import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useGraphqlLoadingComponent} from "../../common/graphql";
import {useMutation, useQuery} from "@apollo/client";
import gql from "graphql-tag";
import Page from "../../common/ui/page";
import {Formik} from "formik";
import {CancelButtonField, FormActions, SldsInputField, SubmitButtonField} from "../../common/ui/form/formElements";
import {Form} from "../../common/ui/form/formik";
import * as Log from "../../common/log";
import {YesNoDialog} from "../../common/slds/modals/YesNoDialog";
import Button from "../../common/slds/buttons/button";
import ButtonGroup from "../../common/slds/buttonGroup/ButtonGroup";
import {OrganisationUserList} from "../../components/organisation/OrganisationUserList";
import {useParams} from "react-router";
import OrganisationLookupField from "../../components/organisation/organisationLookupField";
import {useAuthContext} from "../../common/context/authContext";

const MUTATION_UPDATE_USER_ORG = gql`
    mutation($id: ID!, $input: UserInput) {
        updateUser(id: $id, input: $input) {
            id
            organisation {
                id
                name
            }
        }
    }`;



const OrganisationDetailPage = () => {
    const navigate = useNavigate();
    const auth = useAuthContext()
    const id = useParams().id
    const organisationResult = useQuery(gql`
        query($id: ID!) {
            getOrganisation(id: $id) {
                id
                name
                numUsers
                numDevices
                subOrganisations {
                    id
                }
                createdAt
                dataRetentionTime
                userLimit
                maxSubOrgDepth
                maxSubOrganisations
                parentOrganisation {
                    id
                    name
                }
            }
        }
    `, {
        fetchPolicy: "network-only",
        variables: {
            id: id
        }
    });

    const [mutateUpdateOrga] = useMutation(gql`
        mutation($id: ID!, $input: OrganisationInput) {
            updateOrganisation(id: $id, input: $input) {
                id
            }
        }
    `);

    const [mutateDeleteOrga] = useMutation(gql`
        mutation($id: ID!) {
            deleteOrganisation(id: $id)
        }
    `);

    const [mutateUpdateUser] = useMutation(MUTATION_UPDATE_USER_ORG, {
        variables: {
            id: auth.userId(),
        }
    });


    const deletePending = useState(false);

    const loading = useGraphqlLoadingComponent(organisationResult);
    if (loading) {
        return loading;
    }

    const organisation = organisationResult.data.getOrganisation;

    const evaluateRetentionTime = (newRetentionTime) => {
        if (newRetentionTime !== organisation.dataRetentionTime) {
            return newRetentionTime
        }
        return null
    }

    return <Page
        trail={[
            <Link to="." key={1}>Organisations</Link>,
            <Link to={"./" + id} key={2}>{organisation.name}</Link>]
        }
        title={organisation.name}
        info={<>{organisation.numUsers} Users • {organisation.numDevices} Devices
            • {organisation.subOrganisations.length} Sub Organisations </>}
        actions={<ButtonGroup>
            {organisation.numUsers === 0 && organisation.numDevices === 0 && organisation.subOrganisations.length === 0 ?
                <Button iconName={"delete"} className="slds-button--destructive" onClick={() => deletePending[1](true)}>Delete
                    Organsiation</Button> : null}
        </ButtonGroup>}
    >
        <Formik
            initialValues={{...organisation}}
            initialStatus={{
                readOnly: true,
                canEdit: true
            }}
            onSubmit={(values, actions) => {
                mutateUpdateOrga({
                    variables: {
                        id: id,
                        input: {
                            name: values.name,
                            dataRetentionTime: evaluateRetentionTime(values.dataRetentionTime),
                            parentOrganisationId: values.parentOrganisation?.id || 0,
                            maxSubOrgDepth: values.maxSubOrgDepth !== organisation.maxSubOrgDepth ? values.maxSubOrgDepth : null,
                            maxSubOrganisations: values.maxSubOrganisations !== organisation.maxSubOrganisations ? values.maxSubOrganisations : null,
                            userLimit: values.userLimit !== organisation.userLimit ? values.userLimit : null
                        }
                    }
                }).then(() => {
                    actions.setStatus({canEdit: true, readOnly: true});
                    void organisationResult.refetch();
                }).catch((err) => {
                    Log.Error("Failed to update organisation", err);
                    actions.setFieldError("global", "Failed to update organisation: " + err.message);
                }).finally(() => {
                    actions.setSubmitting(false);
                })
            }}
        >
            <Form className="slds-m-around--small">
                <SldsInputField name={"id"} label={"ID"}/>
                <SldsInputField name={"name"} label={"Name"}/>
                <SldsInputField name={"dataRetentionTime"} label={"Data Retention Time"}/>
                <SldsInputField type={"number"} name={"userLimit"} label={"Max User Limit"}/>
                <OrganisationLookupField label={"Parent Organisation"} name={"parentOrganisation"}/>
                <SldsInputField type={"number"} name={"maxSubOrganisations"} label={"Max Sub-Organisations"}/>
                <SldsInputField type={"number"} name={"maxSubOrgDepth"} label={"Max Depth of Sub-Organisations Tree"}/>
                <FormActions>
                    <SubmitButtonField>Save</SubmitButtonField>
                    <CancelButtonField>Cancel</CancelButtonField>
                </FormActions>
            </Form>
        </Formik>

        <YesNoDialog title={"Delete Organisation"} text={"Delete organisation permanently?"} onYes={() => {
            if (auth.organisationId() === Number(organisation.id)) {
                mutateUpdateUser({
                    variables: {
                        input: {
                            organisationId: auth.baseOrganisationId(),
                        }
                    }
                }).then(() => {
                    auth.refreshTheToken().then(() => {
                        mutateDeleteOrga({variables: {id: organisation.id}}).then(() => {
                                navigate("/configuration/organisations");
                        })
                    });
                }).catch((err) => {
                    Log.Error("Failed to update user", err);
                })
            } else {
                mutateDeleteOrga({variables: {id: organisation.id}})
                    .then(() => {
                        navigate("/configuration/organisations");
                    });

            }
        }} open={deletePending}/>

        <OrganisationUserList organisationId={organisation.id} basePath={"/configuration/users/"}/>

    </Page>;
};

export default OrganisationDetailPage;


