import React from "react";

const DeviceDataIndexPage = () => {
    return <div className="slds-p-around--x-small slds-size--1-of-1">
        <h2 className="slds-text-heading--medium slds-p-around--medium"
            id="entity-header">Welcome</h2>
        Please select a device type from the list.
    </div>
}

export default DeviceDataIndexPage