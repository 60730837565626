import {useState} from "react";

// Helper to search in data tables
export function useMultiSelection() {
    const [selections, setSelections] = useState([]);

    return {
        setSelections: setSelections,
        selections: selections,
        handleSelectionEvent: (event, {selection}) => setSelections(selection)
    }
}