import React from "react";
import Page from "../../common/ui/page";
import {useMutation} from "@apollo/client";
import gql from "graphql-tag";
import Button from "../../common/slds/buttons/button";
import {NotificationMessageBody, useNotificationContext} from "../../notifications/notificationContext";
import moment from "moment";
import {Log} from "../../common/log";
import DescriptionList, {DescriptionListEntry} from "../../common/slds/descriptionList/descriptionList";

const AdminCard = (props) => {
    return <article className="slds-card">
        <div className="slds-card__header slds-grid">
            <header className="slds-media slds-media_center slds-has-flexi-truncate">
                <div className="slds-media__figure">
                        <span className="slds-icon_container slds-icon-standard-account" title="account">
                            <svg className="slds-icon slds-icon_small" aria-hidden="true">
                                <use href="/assets/icons/standard-sprite/svg/symbols.svg#account"></use>
                            </svg>
                            <span className="slds-assistive-text">{props.title}</span>
                        </span>
                </div>
                <div className="slds-media__body">
                    <h2 className="slds-card__header-title">
                        <a className="slds-card__header-link slds-truncate" title="Accounts" href="#" onClick={() => false}>
                            <span>{props.title}</span>
                        </a>
                    </h2>
                </div>
            </header>
        </div>
        <div className="slds-card__body slds-card__body_inner">
            {props.children}
        </div>
    </article>;
};

const AdminPage = () => {

    const [rebuildAppsSearchIndex] = useMutation(gql`
        mutation {
            rebuildApplicationSearchIndex {
                duration
            }
        }
    `);

    const [rebuildDevicesSearchIndex] = useMutation(gql`
        mutation {
            rebuildDevicesSearchIndex {
                duration
            }
        }
    `);

    const [rebuildOrganisationsSearchIndex] = useMutation(gql`
        mutation {
            rebuildOrganisationsSearchIndex {
                duration
            }
        }
    `);

    const [rebuildUsersSearchIndex] = useMutation(gql`
        mutation {
            rebuildUsersSearchIndex {
                duration
            }
        }
    `);
    const [syncAllDeviceDatasources] = useMutation(gql`
        mutation {
            syncAllDeviceDatasources {
                duration
                error
                devicesErrorCnt
                devicesCreatedCnt
                devicesUpdatedCnt
                devicesTotalCnt
            }
        }
    `);

    const notes = useNotificationContext();

    return <Page trail={[]} title={"Index Management"}>

        <AdminCard title={"App-Instances"}>
            <Button iconName={"forward"} onClick={() => {
                rebuildAppsSearchIndex({}).then(result => {
                    notes.create(<NotificationMessageBody message={"Duration: " + result.data.rebuildApplicationSearchIndex.duration + " Milliseconds"}/>, 10000);
                });
            }}>
                Rebuild Apps Search Index
            </Button>
        </AdminCard>

        <AdminCard title={"Devices"}>
            <Button iconName={"forward"} onClick={() => {
                rebuildDevicesSearchIndex({}).then(result => {
                    notes.create(<NotificationMessageBody message={"Duration: " + result.data.rebuildDevicesSearchIndex.duration + " Milliseconds"}/>, 10000);
                });
            }}>
                Rebuild Devices Search Index
            </Button>
            <Button iconName={"sync"} onClick={() => {
                let start = moment();
                syncAllDeviceDatasources({}).then(result => {
                    let duration = moment.duration(moment().diff(start));
                    Log.Debug("duration:" ,duration)
                    /**
                     devicesErrorCnt
                     devicesSyncedCnt
                     devicesTotalCnt
                     */
                    const res = result.data.syncAllDeviceDatasources;

                    const notification = <NotificationMessageBody>
                        <DescriptionList>
                            <DescriptionListEntry truncateLabels={false} label={"Duration"} description={result.data.syncAllDeviceDatasources.duration + " ms"}/>
                            <DescriptionListEntry truncateLabels={false} label={"Devices"} description={res.devicesTotalCnt}/>
                            <DescriptionListEntry truncateLabels={false} label={"Created"} description={res.devicesCreatedCnt}/>
                            <DescriptionListEntry truncateLabels={false} label={"Updated"} description={res.devicesUpdatedCnt}/>
                            <DescriptionListEntry truncateLabels={false} label={"Failed"} description={res.devicesErrorCnt}/>
                        </DescriptionList>
                    </NotificationMessageBody>;

                    notes.create(notification);
                });
            }}>
                Sync all device datasources
            </Button>
        </AdminCard>

        <AdminCard title={"Organisations"}>
            <Button iconName={"forward"} onClick={() => {
                rebuildOrganisationsSearchIndex({}).then(result => {
                    notes.create(<NotificationMessageBody message={"Duration: " + result.data.rebuildOrganisationsSearchIndex.duration + " Milliseconds"}/>, 10000);
                });
            }}>
                Rebuild Organisations Search Index
            </Button>
        </AdminCard>

        <AdminCard title={"Users"}>
            <Button iconName={"forward"} onClick={() => {
                rebuildUsersSearchIndex({}).then(result => {
                    notes.create(<NotificationMessageBody message={"Duration: " + result.data.rebuildUsersSearchIndex.duration + " Milliseconds"}/>, 10000);
                });
            }}>
                Rebuild Users Search Index
            </Button>
        </AdminCard>


    </Page>;
};
export default AdminPage;