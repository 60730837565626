import React from "react";
import PropTypes from "prop-types";
import {Button} from "@salesforce/design-system-react";
import classNames from 'classnames';
import {Field, Formik} from 'formik';
import {DateTimePicker, SldsFormElementCompound, SldsFormElementRow} from './form/formElements'
import {Form} from './form/formik'
import * as log from "../log";
import {useT} from "../i18n";

// Map the filters to graphQL "FilterInputType"
// [{"field": "a", "op": "lte", "value": "123"}]
function filtersToGraphQL(filters) {
    let gqlFilters = [];

    if (!filters) {
        return gqlFilters;
    }

    if (filters.until) {
        gqlFilters.push({"field": 'createdAt', "op": "lte", "value": new Date(filters.until.getTime())})
    }
    if (filters.from) {
        gqlFilters.push({"field": 'createdAt', "op": "gte", "value": new Date(filters.from.getTime())})
    }
    return gqlFilters;
}


FilterPanel.propTypes = {
    align: PropTypes.oneOf(["center", "left", "right"]),
    size: PropTypes.oneOf(["small", "medium", "large", "x-large", "full"]),
    onClose: PropTypes.func,
    filters: PropTypes.object // From useFilter() Hook
};

FilterPanel.defaultProps = {
    align: "left",
    size: "medium",
    filters: {
        'until': new Date(),
    }
}



export default function FilterPanel(props) {
    const t = useT();
    const {filters, align, className, size} = props;
    const {setFilterTabVisible} = filters
    const docked = align !== 'center';

    let from, until;
    for (let filter of filters.filter) {
        if (filter.field === 'createdAt' && filter.op === 'gte') {
            from = new Date(filter.value.getTime());
        }
        if (filter.field === 'createdAt' && filter.op === 'lte') {
            until = new Date(filter.value.getTime());
        }
    }
    return <div
        className={
            classNames(
                {
                    'slds-is-open': true,
                    [`slds-panel_docked-${align}`]: docked,
                    'slds-panel_docked"': docked,
                },
                `slds-size_${size}`,
                "lds-panel",
                className,
            )}

        aria-hidden="false">
        <div className="slds-panel__header">
            <div className="slds-panel__header-title slds-text-heading_small slds-truncate"
                 title="Panel Header">Filter</div>
            <Button iconCategory="utility"
                    variant="icon"
                    iconName="close"
                    iconSize="small"
                    assistiveText={{icon: "Close filter panel"}}
                    className="slds-button--icon slds-panel__close"
                    onClick={() => setFilterTabVisible(false)}
            />
        </div>
        <div className="slds-panel__body">
            {/* Render the filter options*/}
            <Formik
                initialValues={{
                    until: until,
                    from: from,
                }}

                onSubmit={(values, actions) => {
                    log.Debug("Submit: ", values, actions);
                    const newFilters = filtersToGraphQL(values);
                    filters.setFilter(newFilters);
                    actions.setSubmitting(false);
                }}
                onReset={() => {
                    log.Debug("Clearing Filters");
                    filters.setFilter([])
                }}
                render={() => {
                    return <Form>
                        <SldsFormElementCompound>
                            <SldsFormElementRow>
                                <Field component={DateTimePicker} submitFormOnChange={true} label={t("filter-panel.from", "From")} name="from"/>
                                <Field component={DateTimePicker} submitFormOnChange={true} label={t("filter-panel.until", "Until")} name="until"/>
                            </SldsFormElementRow>
                        </SldsFormElementCompound>
                    </Form>
                }
                }
            />
        </div>
    </div>
}
