import {Formik} from "formik";
import {Log} from "../common/log";
import {NotifyUser} from "../common/userNotification";
import {Form} from "../common/ui/form/formik";
import {FormActions, SldsInputField, SldsTextareaField, SubmitButtonField} from "../common/ui/form/formElements";
import ErrorBoundary from "../common/ui/errorBoundary";
import WmbusTelegramDetails from "../app/wmbus/wmbusTelegram";
import {Accordion, AccordionPanel} from "../common/ui/accordion";
import Json from "../common/ui/json";
import React from "react";
import {useQuery} from "@apollo/client";
import gql from "graphql-tag";
import queryString from "query-string";
import {useLocation, useNavigate} from "react-router-dom";
import {useT} from "../common/i18n";


const QUERY_WMBUS_API = gql`
    query ($raw: String!, $key: String) {
        wmbus: parseWmbus(raw: $raw, key: $key) {
            data
            parseError
        }
    }
`;


const ParserTab = () => {
    const t = useT();
    let navigate = useNavigate();
    let location = useLocation();
    let params = queryString.parse(location.search)
    Log.Debug("location", location, params)

    let parsed = {};
    let parseError = "";
    let raw = params.raw ? params.raw : "2e44931578563412330333637a2a0020055923c95aaa26d1b2e7493b2a8b013ec4a6f6d3529b520edff0ea6defc955b29d6d69ebf3ec8a";
    let key = params.key ? params.key : "0102030405060708090A0B0C0D0E0F11";


    const queryParseWmbus = useQuery(QUERY_WMBUS_API, {
        skip: false,
        variables: {
            raw: raw,
            key: key,
        }
    });

    const {data} = queryParseWmbus;
    if (data && data.wmbus) {
        parseError = data.wmbus.parseError;
        try {
            parsed = JSON.parse(data.wmbus.data);
        } catch (e) {
            NotifyUser.Error(t("wmbus-parser.notify.failed-to-parse-json", "Failed to parse json result"), e);
        }
    }

    return <div className="slds-p-horizontal--medium slds-p-bottom--medium">
        <Formik initialValues={{
            raw: raw,
            key: key,
        }}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                    //base64 needs to be converted to base64url:
                    let newRaw = base64ToBase64UrlEncoded(values.raw)
                    let newKey = base64ToBase64UrlEncoded(values.key)
                    navigate(`${location.pathname}?raw=${newRaw}&key=${newKey}`);
                    actions.setSubmitting(false);
                }}
        >
            <Form>
                <SldsTextareaField name={"raw"} label={t("wmbus-parser.wmbus-telegram-hex","wMbus Telegram (Hex)")} required={true}
                                   rows={5}
                />
                <SldsInputField name={"key"} label={t("wmbus-parser.key-optional","Key (optional)")}/>

                <FormActions>
                    <SubmitButtonField>{t("wmbus-parser.parse-button","Parse wMbus Telegram")}</SubmitButtonField>
                </FormActions>
            </Form>
        </Formik>

        <div>
            <div className="slds-text-heading_large slds-m-top--small">{t("wmbus-parser.parse-result-heading","Parse Result")}</div>
            <ErrorBoundary>
                <WmbusTelegramDetails mbusJson={parsed} parseError={parseError}/>
                <Accordion>
                     <AccordionPanel summary={t("wmbus-parser.wmbus-data-json","wMBus Data (Json)")} id="json-panel">
                        <Json json={parsed}/>
                    </AccordionPanel>
                </Accordion>
            </ErrorBoundary>
        </div>
    </div>
};
export default ParserTab;

function base64ToBase64UrlEncoded(base64Value) {
    return base64Value
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/g, '');
}