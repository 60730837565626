import * as React from "react";
import {useEffect, useState} from "react";
import Page from "../../common/ui/page";
import {QUERY_HARDWARE_LIST} from "../queries";
import {useQuery} from "@apollo/client";
import GenericDataTable from "../../common/ui/genericDataTable/genericDataTable";
import DataTableColumn from "../../common/ui/data-table/column";
import {Link, useNavigate} from "react-router-dom";
import {ButtonGroup} from "@salesforce/design-system-react";
import AddHardwareDialog from "./addHardwareDialog";
import {usePagination} from "../../common/hooks/usePagination";
import {useSearch} from "../../common/hooks/useSearch";
import {useMultiSelection} from "../../common/hooks/useMultiSelection";
import Button from "../../common/slds/buttons/button";
import {Log} from "../../common/log";
import {HardwareBulkOperationPage} from "./HardwareBulkOperationPage"
import DataTableCell from "../../common/ui/data-table/cell";


const HardwareListTable = (props) => {
     return <GenericDataTable id={"hardware-table"} items={props.result.data && props.result.data.devices || []} tableConfigDefault={{}}
                             gqlResult={props.result}
                             selection={props.selection}
                             page={props.page} search={props.search}>
        <DataTableColumn label="Serial" property={"serial"}>
            <DataTableCell key="serial" render={(props) => (
                <Link to={`${props.item.id}`}>{props.item.serial}</Link>
            )}/>
        </DataTableColumn>
        <DataTableColumn label="Address" property="addr" title={"Addr"}>
            <DataTableCell key="id" render={(props) => (
                <Link to={`${props.item.id}`}>{props.item.addr}</Link>
            )}/>
        </DataTableColumn>
        <DataTableColumn label="Name" property="name" title={"Name"}>
            <DataTableCell key="id" render={(props) => (
                <Link to={`${props.item.id}`}>{props.item.name}</Link>
            )}/>
        </DataTableColumn>
        <DataTableColumn label="Device Type" property={"deviceType"}>
            <DataTableCell key="type" render={(props) => (
                <Link to={`/deviceTypes/${props.item.deviceType.id}`}>{props.item.deviceType.displayName}</Link>
            )}/>
        </DataTableColumn>
        <DataTableColumn label="App" property={"app"}>
            <DataTableCell key="type" render={(props) => (
                props.item.app ? <Link to={`/configuration/applications/${props.item.app.id}`}>{props.item.app.name}</Link> : null
            )}/>
        </DataTableColumn>
        <DataTableColumn label="Organization" property={"Organization"}>
            <DataTableCell key="type" render={(props) => (
                props.item.organisation ? <Link to={`/configuration/organisations/${props.item.organisation.id}`}>{props.item.organisation.name}</Link> : null
            )}/>
        </DataTableColumn>
    </GenericDataTable>
};


const HardwareListPage = () => {
    const page = usePagination();
    const search = useSearch();
    const navigate = useNavigate();
    const multiSelection = useMultiSelection();

    const result = useQuery(QUERY_HARDWARE_LIST, {
        variables: {
            page: page.getGraphqlPageInput(),
            search: search.getGraphqlSearchInput()
        }
    });

    const [showNewHardwareModal, setShowNewHardwareModal] = useState(false);

    const [showBulkOperationPage, setShowBulkOperationPage] = useState(false);

    useEffect(() => {
        Log.Debug("Selection: ", multiSelection.selections )
    },[multiSelection.selections]);

    if (showBulkOperationPage){
        return <HardwareBulkOperationPage setShowBulkOperationPage={(b) => setShowBulkOperationPage(b)} page={page} multiSelection={multiSelection} />
    }
    return <Page
        trail={[<Link to="./hardware" key={1}>Hardware List</Link>]}
        title={"Hardware"}
        actions={<ButtonGroup>
            <Button iconName={"edit"} onClick={() =>  setShowBulkOperationPage(true)} disabled={!multiSelection.selections.length} >Bulk Operations</Button>
            <Button iconName={"add"} onClick={() => setShowNewHardwareModal(true)}>New Hardware</Button>
            <Button iconName={"upload"} onClick={() => navigate("/configuration/hardware/import")}>Import</Button>
        </ButtonGroup>}
    >
        <AddHardwareDialog isOpen={showNewHardwareModal} onRequestClose={() => setShowNewHardwareModal(false)}
                           onAdded={(hw) => navigate("/configuration/hardware/" + hw.id)}/>


        <HardwareListTable result={result} page={page} search={search} selection={multiSelection}/>

    </Page>;
};

export default HardwareListPage;