import React, {useEffect} from "react";
import PropTypes from "prop-types";
import GenericDataTable from "../../common/ui/genericDataTable/genericDataTable";
import {usePagination} from "../../common/hooks/usePagination";
import {useSort} from "../../common/hooks/useSort";
import {useMutation, useQuery} from "@apollo/client";
import gql from "graphql-tag";
import {useGraphqlLoadingComponent} from "../../common/graphql";
import {useParams} from "react-router-dom";
import {useFilter} from "../../common/hooks/useFilter";
import {useCsvExport} from "../../common/hooks/useCsvExport";
import {Log} from "../../common/log";
import {ParsedDataDetailComponent} from "../../domain/traits/traits";
import {useAuthContext} from "../../common/context/authContext";
import {useNotificationContext} from "../../notifications/notificationContext";
import {MUTATE_UPDATE_DEVICE_TYPE, QUERY_DEVICE_TYPE} from "../../deviceTypes/queries";
import {DEFAULT_DATA_TABLE_CONFIG} from "../../deviceTypes/deviceTypesDetailPage";
import {useT} from "../../common/i18n";


const QUERY_DEVICE_TYPE_PARSED_DATA = gql`
    query ($page:PaginationInputType, $filter:[FilterInputType!], $sort: SortInputType, $devTypeId: ID!, $orgId: ID) {
        deviceType(id: $devTypeId) {
            id
            dataTableConfigRaw
            deviceTraits
        }
        parsedData(page: $page, filter: $filter, sort: $sort, devTypeId: $devTypeId, orgId: $orgId) {
            id
            time
            createdAt
            deviceId
            dataRaw
            type
            sensorDataId
            device {
                id
                name
                addr
                description
                propertiesRaw
            }
            datasource {
                id
                name
            }
        }
    }
`;

const DeviceTypeParsedData = () => {
    const t = useT();
    const ninetyDays = 1000*60*60*24*90
    const params = useParams();
    const deviceTypeId = params.deviceTypeId;
    const auth = useAuthContext();


    const notify = useNotificationContext();


    let initialFilter = [{"field": 'createdAt', "op": "lte", "value": new Date(Date.now())},
        {"field": 'createdAt', "op": "gte", "value": new Date(Date.now()-ninetyDays)}]
    const filters = useFilter(true, initialFilter);
    const sort = useSort(null);
    const page = usePagination(100)

    const gqlResult = useQuery(QUERY_DEVICE_TYPE_PARSED_DATA, {
        variables: {
            devTypeId: deviceTypeId,
            orgId: auth.organisationId(),
            filter: filters.getGraphqlFilterInput(),
            sort: sort.getGraphqlSortInput(),
            page: page.getGraphqlPageInput()
        }
    });

    useEffect(() => {
        filters.reset()
        sort.reset()
        page.reset()

        void gqlResult.refetch({
        devTypeId: deviceTypeId,
        sort: sort.getGraphqlSortInput(),
        filter: filters.getGraphqlFilterInput(),
        page: page.getGraphqlPageInput()
    })}, [deviceTypeId])

    const [updateDeviceType] = useMutation(MUTATE_UPDATE_DEVICE_TYPE, {
        variables: {id: deviceTypeId},
        refetchQueries: [{
            query: QUERY_DEVICE_TYPE,
            variables: {
                id: deviceTypeId,
            }
        }]
    });

    // Takes parsed data and unmarshal json fields
    const parsedDataMapper = (d) => {
        return {
            ...d,
            data: JSON.parse(d.dataRaw),
            //deviceProperties: JSON.parse(d.device.propertiesRaw || "{}") || {},
            dataRaw: undefined, // Hide dataRaw
        };
    };

    const csvExport = useCsvExport(QUERY_DEVICE_TYPE_PARSED_DATA, {
        variables: {
            devTypeId: deviceTypeId,
            orgId: auth.organisationId(),
            filter: filters.getGraphqlFilterInput(),
            sort: sort.getGraphqlSortInput()
        },
        //dataExtractor: d => d.data.parsedData
        dataExtractor: d => d.data.parsedData.map(parsedDataMapper)
    });

    const loading = useGraphqlLoadingComponent(gqlResult);
    if (loading) {
        return loading;
    }
    let deviceType = gqlResult.data.deviceType;
    let deviceTraits = deviceType.deviceTraits;
    let tableConfig = deviceType.dataTableConfigRaw && JSON.parse(gqlResult.data.deviceType.dataTableConfigRaw);

    if (!tableConfig) {
        tableConfig = DEFAULT_DATA_TABLE_CONFIG;
    }

    Log.Debug("TARIS:", deviceTraits);

    const prefixCols = [{
        heading: t("device-type.data-table-config.heading.received", "Received"),
        csvFormat: "{{date createdAt}}",
        cell: {
            format: "{{date createdAt}}",
        }
    },
        {
            heading: t("device-type.data-table-config.heading.device", "Device"),
            csvFormat: "{{#if device.name}}{{device.name}}{{else}}- no name -{{/if}}",
            cell: {
                format: "{{#if device.name}}{{device.name}}{{else}}- no name -{{/if}}",
                href: "#/organisation/devices/{{device.id}}/device-data",
            }
        },
        {
            heading: t("device-type.data-table-config.heading.address", "Address"),
            csvFormat: "{{device.addr}}",
            cell: {
                format: "{{device.addr}}",
                href: "#/organisation/devices/{{device.id}}/device-data",
            }
        }];

    const data = gqlResult.data;

    return <GenericDataTable
        id={"parsed-data-table"}
        fixedLayout={false}
        tableConfigDefault={tableConfig}
        handleTableConfigSave={(values) => {
            return updateDeviceType({
                variables: {
                    input: {
                        dataTableConfig: JSON.stringify(values),
                    }
                }
            }).then(() => {
                notify.info("Data Table Config for Device Type updated.")
            }).catch((err) => {
                notify.error(t("device-type.table-config.update-failed", "Failed to save Table Config"), err);
            });
        }}
        items={data.parsedData.map(({dataRaw, ...d}) => {
            return {
                ...d,
                data: JSON.parse(dataRaw)
            };
        })}
        // TODO: render trait based details: renderDetails={(item) => <WmbusDetailComponent item={item}/>}
        prefixCols={prefixCols}
        renderDetails={(item) => <ParsedDataDetailComponent traits={deviceType.deviceTraits} data={item}/>}
        gqlResult={gqlResult}
        sort={sort}
        page={page}
        filters={filters}
        csvExport={csvExport}
    />;
};

export default DeviceTypeParsedData;

DeviceTypeParsedData.propTypes = {
    "deviceTypeId": PropTypes.number,
    "tableConfig": PropTypes.object,
};