import React from "react"
import gql from "graphql-tag"
import { useMutation, useQuery } from "@apollo/client"
import { useAuthContext } from "../common/context/authContext"
import { Log } from "../common/log"

type Org = {
    id: string,
    name: string,
}

const QUERY_ORG_PATH = gql`
    query($id: ID!) {
        getOrganisationParentPath(id: $id) {
            id
            name
            parentOrganisation {
                id
                name
            }
        }
    }`

const MUTATION_UPDATE_USER_ORG = gql`
    mutation($id: ID!, $input: UserInput) {
        updateUser(id: $id, input: $input) {
            id
            organisation {
                id
                name
            }
        }
    }`

const OrganizationBreadcrumbs = () => {
    const auth = useAuthContext()
    const orgPathResult = useQuery(QUERY_ORG_PATH, {
        variables: { id: auth.organisationId() },
    })
    const [mutateUpdateUser] = useMutation(MUTATION_UPDATE_USER_ORG)
    if (orgPathResult.loading) {
        return null
    }
    const orgPathError = orgPathResult.error
    console.log("orgPathError MSG", orgPathError?.message)
    if (orgPathError && orgPathError.message.startsWith("error loading org with id:")) {
        auth.refreshTheToken().then(() => {
            location.reload()
        })
    }
    const orgPath = [...orgPathResult.data?.getOrganisationParentPath].reverse()


    const changeOrg = (orgId: string) => {
        mutateUpdateUser({
            variables: {
                id: auth.userId(),
                input: {
                    organisationId: orgId,
                },
            },
        }).then(() => {
            auth.refreshTheToken().then(() => {
                location.reload()
            })
        }).catch((err) => {
            Log.Error("Failed to update user", err)
        })
    }

    const renderBC = (org: Org) => {
        return <a onClick={() => changeOrg(org.id)}>{org.name}</a>
    }

    const filterBreadcrumbs = (orgPath: Array<Org>) => {
        const length = orgPath.length
        if (length > 3) {
            return <div>
                {renderBC(orgPath[0])} {" >...> "}
                {renderBC(orgPath[length - 2])} {" > "}
                {orgPath[length - 1].name}
            </div>

        } else if (length === 3) {
            return <div>
                {renderBC(orgPath[0])} {" > "}
                {renderBC(orgPath[length - 2])} {" > "}
                {orgPath[length - 1].name}
            </div>
        } else if (length === 2) {
            return <div>
                {renderBC(orgPath[0])} {" > "}
                {orgPath[length - 1].name}
            </div>
        } else if (length === 1) {
            // already in root org
            return null
        }
    }

    return filterBreadcrumbs(orgPath)
}

export default OrganizationBreadcrumbs
