import React, {useState} from "react";
import Page from "../../common/ui/page";
import {useMutation, useQuery} from "@apollo/client";
import {MUTATE_UPDATE_ACTIVATION_GROUP, QUERY_HARDWARE_LIST} from "../queries";
import {useGraphqlLoadingComponent} from "../../common/graphql";
import {Link, useNavigate} from "react-router-dom";
import * as Yup from "yup";
import * as Log from "../../common/log";
import {FieldArray, Formik, insert} from "formik";
import {
    CancelButtonField,
    FormActions,
    SldsFormElementCompound,
    SldsInputField,
    SubmitButtonField
} from "../../common/ui/form/formElements";
import {Form} from "../../common/ui/form/formik";
import {NotifyUser} from "../../common/userNotification";
import Lookup from "../../common/ui/lookup/lookup";
import gql from "graphql-tag";
import {YesNoDialog} from "../../common/slds/modals/YesNoDialog";
import Button from "../../common/slds/buttons/button";
import HorizontalList from "../../common/slds/horizontalList/HorizontalList";
import {HorizontalListItem} from "../../common/slds/horizontalList/HorizontalListItem";
import {Icon} from "../../common/slds/icons/icon";
import {useParams} from "react-router";

const ActivationGroupDetailPage = () => {
    const id = useParams().id
    const deletePending = useState(false);
    const navigate = useNavigate();
    const activationGroupDetailResult = useQuery(gql`
        query($id: ID!) {
            getActivationGroup(id: $id) {
                id
                nr
                activationCode
                deviceIds
                createdAt
                devices {
                    id
                    addr
                    deviceType {
                        id
                        displayName
                    }
                    initialConfigRaw
                    propertiesRaw
                }
            }
        }`, {
        variables: {
            id: id,
        }
    });

    const hardwareListResult = useQuery(QUERY_HARDWARE_LIST, {
        variables: {
            page: {
                offset: 0,
                limit: 10
            },
            idNotIn: []
        }
    });

    const [updateActivationGroup] = useMutation(MUTATE_UPDATE_ACTIVATION_GROUP, {
        variables: {id}
    });

    const [deleteActivationGroup] = useMutation(gql`mutation($id: ID!) {
        deleteActivationGroup(id: $id)
    }`, {
        variables: {id}
    });


    const loadingDN = useGraphqlLoadingComponent(activationGroupDetailResult);
    if (loadingDN) {
        return loadingDN;
    }

    const activationGroupDetail = activationGroupDetailResult.data.getActivationGroup;
    Log.Debug("ActivationGroupDetail", activationGroupDetail);

    return <Page
        trail={[<Link to="/configuration/activationGroups" key={1}>Activation Groups</Link>,
            <Link to={"/configuration/activationGroups/" + activationGroupDetail.id} key={2}>{activationGroupDetail.nr}</Link>]}
        title={activationGroupDetail.nr}
        actions={<>
            <Link to={`/hardwareActivation/${activationGroupDetail?.activationCode}`} target="_blank"><Button iconName={"preview"}>Show Activation Page</Button></Link>
            <Button iconName="delete" className="slds-button--destructive" onClick={() => deletePending[1](true)}>Delete</Button>
        </>}
    >
        <Formik
            initialValues={{
                nr: activationGroupDetail.nr,
                activationCode: activationGroupDetail.activationCode,
                hardwareList: activationGroupDetail.devices
            }}
            initialStatus={{
                readOnly: true,
                canEdit: true,
            }}
            enableReinitialize={true}
            validationSchema={Yup.object().strict().shape({
                nr: Yup.string().required().trim()
            })}
            onSubmit={(values, actions) => {
                Log.Debug("submit", values);
                updateActivationGroup({
                    variables: {
                        id,
                        input: {
                            Nr: values.nr,
                            DeviceIds: values.hardwareList.map(hw => hw.id)
                        }
                    }
                }).then(() => {
                    actions.setStatus({canEdit: true, readOnly: true});
                }).catch((err) => {
                    NotifyUser.Error("Failed to update Activation Group", err);
                }).finally(() => {
                    actions.setSubmitting(false);
                });
            }} render={(formik) => <Form className="slds-m-around--small">
            <SldsInputField name={"nr"} label={"Name"}/>
            <SldsInputField name={"activationCode"} label={"Activation Code"} readOnly={true}/>
            <SldsFormElementCompound label={"Hardware"}>
                <FieldArray name={"hardwareList"}
                            render={(arrayHelpers) => <table id={"hardware-list"}>
                                <thead>
                                <tr>
                                    <th>id</th>
                                    <th>Address</th>
                                    <th>Device Type</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {formik.values.hardwareList.map((hardware, idx) => <tr key={idx}>
                                    <td>{hardware.id}</td>
                                    <td>{hardware.addr}</td>
                                    <td>{hardware.deviceType.displayName}</td>
                                    <td>
                                        {!formik.status.readOnly && <Button
                                            variant={"icon"} iconCategory={"action"} iconName={"remove"}
                                            iconSize={"small"} iconVariant={"bare"}
                                            onClick={() => arrayHelpers.remove(idx)}/>}
                                    </td>
                                </tr>)}
                                </tbody>
                            </table>}
                />
            </SldsFormElementCompound>
            <Lookup
                name={"hardwareList"}
                label={"Add Hardware"}
                value={formik.values.hardwareList}
                readOnly={formik.status?.readOnly}
                onLookup={(value) => {
                    let list = formik.values["hardwareList"] || [];
                    formik.setFieldValue("hardwareList", insert(list, list.length, value));
                }}
                iconExtractor={() => <Icon name={"shipment"} category={"standard"}/>}
                titleExtractor={item => <HorizontalList>
                    <HorizontalListItem><b>{item.name}</b></HorizontalListItem>
                    <HorizontalListItem>{item.addr}</HorizontalListItem>
                </HorizontalList>}
                subtitleExtractor={item => <div>
                    <div>{item?.deviceType?.displayName}</div>
                    <div>{item.comment}</div>
                </div>}
                loadSuggestions={(value) => {
                    Log.Debug("Suggestions", value);
                    return hardwareListResult.refetch({search: value, idNotIn: formik.values.hardwareList.map(it => it.id)})
                        .then(result => result.data.devices);
                }}/>
            <FormActions>
                <SubmitButtonField>Save</SubmitButtonField>
                <CancelButtonField/>
            </FormActions>
        </Form>}/>
        <YesNoDialog open={deletePending} title={"Delete ActivationGroup"} text={"Do you really want to delete this activation group"} onYes={() => {
            deleteActivationGroup().then(() => navigate("/configuration/activationGroups"));
        }}/>
    </Page>;
};

export default ActivationGroupDetailPage;
