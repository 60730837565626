import {useAuthContext} from "../common/context/authContext";
import AppOverview from "./appOverview";
import React from "react";
import ToolsOverviewPanel from "./toolsOverviewPanel";
import DevicesOverview from "./devicesOverview";
import {Accordion, AccordionPanel} from "../common/ui/accordion";
import UsageStatisticsOverview from "./usageStatisticsOverview";

const UserHomePage = () => {
    const auth = useAuthContext();

    return <Accordion>
        {auth.isLoggedIn() && <AccordionPanel id={"usageStatistics"} summary={"Usage Statistics"} expanded={true}>
            <UsageStatisticsOverview/>
        </AccordionPanel>}
        {auth.isLoggedIn() && <AppOverview/>}
        {auth.isLoggedIn() && <AccordionPanel id={"devices"} summary={"Devices"} expanded={true}>
            <DevicesOverview/>
        </AccordionPanel>}
        <ToolsOverviewPanel/>
    </Accordion>;
};
export default UserHomePage
