import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {useT} from "../common/i18n";
import {FrontendConfigContext} from "../common/context/frontendConfigContext";
import {useAuthContext} from "../common/context/authContext";
import {AccordionPanel} from "../common/ui/accordion";

const ToolsOverviewPanel = () => {
    const frontendConfig = useContext(FrontendConfigContext);
    const t = useT()
    const authCtx = useAuthContext();
    const tools = []
    if (authCtx.isLoggedIn() || frontendConfig?.wmbusParserApiPublic)
        tools.push(
            {
                "name": t("tools-overview.wmbus-parser", "wMBus Parser"),
                "link": "/wmbus",
                "description": t("tools-overview.wmbus-parser-description", "Parser for the wMBus protocol")
            }
        );
    if (frontendConfig?.hardwareActivationEnabled) {
        tools.push(
            {
                "name": t("tools-overview.hardware-activation", "Hardware Activation"),
                "link": "/hardwareActivation",
                "description": t("tools-overview.hardware-activation-description", "Data export of device data")
            }
        );
    }
    if (tools.length === 0) {
        return null
    }
    return <AccordionPanel id={"tools"} summary={"Tools"} expanded={true}>
        <div className="slds-m-around--medium">
            <ul className="slds-grid slds-wrap slds-grid_pull-padded-xx-small">
                {tools.map((tool, i) => <li key={i}
                    className="slds-size--1-of-1 slds-medium-size_1-of-3 slds-large-size--1-of-4 slds-p-horizontal--xx-small slds-m-bottom--x-small">
                    <article className="slds-box slds-box--x-small">
                        <div className="slds-tile__detail slds-p-bottom--large">
                            <p className="slds-truncate">{tool.name}</p>
                            <p className="slds-text-heading_medium">
                                <Link to={tool.link}>{tool.name}</Link>
                            </p>
                            <p className="slds-truncate">
                                {tool.description}
                            </p>
                        </div>
                    </article>
                </li>)
                }
            </ul>
        </div>
    </AccordionPanel>    ;
};

export default ToolsOverviewPanel;