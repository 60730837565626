import * as React from "react";
import {useState} from "react";
import Page from "../app/pages/page";
import {Link, Outlet, useNavigate} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {
    MUTATE_COPY_DEVICE_TYPE,
    MUTATE_UPDATE_DEVICE_TYPE,
    QUERY_DEVICE_TYPE,
    EXPORT_DEVICE_TYPE
} from "./queries";
import {useGraphqlLoadingComponent} from "../common/graphql";
import {Tab, TabNavigation} from "../common/ui/tabNavigation";
import {YesNoDialog} from "../common/slds/modals/YesNoDialog";
import gql from "graphql-tag";
import Button from "../common/slds/buttons/button";
import {useAuthContext} from "../common/context/authContext";
import {useParams} from "react-router";
import {Modal} from "../common/slds";
import {Formik} from "formik";
import {Log} from "../common/log";
import {Form} from "../common/ui/form/formik";
import {CancelButtonField, FormActions, SldsInputField, SubmitButtonField} from "../common/ui/form/formElements";
import OrganisationLookupField from "../components/organisation/organisationLookupField";
import {useNotificationContext} from "../notifications/notificationContext";
import {useT} from "../common/i18n";
import {download} from "../common/download";

export function DEFAULT_DEVICE_TABLE_CONFIG(t) {
    return {
        cols: [
            {
                heading: t("device-type.device-table-config.heading.date", "Date"),
                cell: {
                    format: "{{date createdAt}}",
                }
            },
            {
                heading: t("device-type.device-table-config.heading.device", "Device"),
                cell: {
                    format: "{{#if device.name}}{{device.name}}{{else}}- no name -{{/if}}",
                    href: "#/organisation/devices/{{device.id}}/device-data",
                }
            },
            {
                heading: t("device-type.device-table-config.heading.address", "Address"),
                cell: {
                    format: "{{device.addr}}",
                    href: "#/organisation/devices/{{device.id}}/device-data",
                }
            },
        ]
    }
}

export const DEFAULT_DATA_TABLE_CONFIG = {
    cols: []
};

export function DeviceTypesDetailPage() {
    const auth = useAuthContext();
    const navigate = useNavigate();
    const deletePending = useState(false);
    const [showCopyDeviceTypeModal, setShowCopyDeviceTypeModal] = useState(false);
    const notify = useNotificationContext()
    const deviceTypeId = useParams().id;
    const t = useT()

    const deviceTypeResult = useQuery(QUERY_DEVICE_TYPE, {
        variables: {
            id: deviceTypeId
        }
    });

    const [updateDeviceType] = useMutation(MUTATE_UPDATE_DEVICE_TYPE, {
        variables: {id: deviceTypeId},
        refetchQueries: [{
            query: QUERY_DEVICE_TYPE,
            variables: {
                id: deviceTypeId,
            }
        }]
    });

    const deviceTypeExport = useQuery(EXPORT_DEVICE_TYPE, {
        skip: true
    });


    const [copyDeviceType] = useMutation(MUTATE_COPY_DEVICE_TYPE);


    const [deleteDeviceType] = useMutation(gql`
        mutation($id: ID!) {
            deleteDeviceType(id: $id)
        }`, {
        variables: {id: deviceTypeId}
    });

    const loadingHW = useGraphqlLoadingComponent(deviceTypeResult);
    if (loadingHW) {
        return loadingHW;
    }
    const deviceType = deviceTypeResult.data.deviceType;

    function exportType(){
        deviceTypeExport.refetch({id: deviceType.id}).then((result) => {
            let fileContent = result.data.deviceTypeExport.zipBase64
            let fileName = result.data.deviceTypeExport.fileName
            let zipContentArrayBuffer = base64ToBuffer(fileContent)
            download(zipContentArrayBuffer, fileName, 'application/zip')
            notify.info(t("device-type.export-success", "Exported device type successfully"))
        }).catch((err) => {
            notify.error(t("device-type.export-failed", "Export failed"), err)
        })
    }


    const canEdit = auth.hasRole("admin") || (deviceType.private && deviceType.organisationId === auth.organisationId() && auth.hasRole("org-admin"));

    return <Page
        trail={[<Link to={"/deviceTypes"} key={1}>Device Types</Link>,
            <Link to={`/deviceTypes/${deviceType.id}`} key={2}>{deviceType.displayName}</Link>]}
        title={`${deviceType.displayName}`}
        info={<>{deviceType.name} • {deviceType.numDevices} Devices  • {deviceType.private ? "Private" : "Public"}</>}
        actions={<>
        { canEdit &&  <Button iconName={"download"}
                onClick={() => exportType()}>{t("device-types.export-button", "Export")}</Button>
        }
        { canEdit &&  <Button iconName={"copy"}
                  onClick={() => setShowCopyDeviceTypeModal(true)}>{t("device-types.copy-button", "Copy")}</Button>
        }
        {canEdit && deviceType.numDevices === 0 &&
        <Button iconName={"delete"} className="slds-button--destructive" onClick={() => deletePending[1](true)}>{t("device-types.delete-button", "Delete")}</Button>}
        </>}
    >
        <TabNavigation tabs={[
            <Tab key={"overview"} to={"overview"}>Overview</Tab>,
            <Tab key={"parser"} to={"parser"}>Parser</Tab>,
            <Tab key={"device-table-config"} to={"deviceTableConfig"}>Device Table Config</Tab>,
            <Tab key={"data-table-config"} to={"dataTableConfig"}>Data Table Config</Tab>,
        ]}>
            <div className="slds-m-around--small">
                <Outlet context={{updateDeviceType, deviceType}} />
            </div>
            <YesNoDialog open={deletePending} title={"Really delete"}
                         text={"Do you really want to delete the device type?"} onYes={() => {
                deleteDeviceType().then(() => navigate("/deviceTypes"))
            }}/>

            <Modal isOpen={showCopyDeviceTypeModal} onRequestClose={() => setShowCopyDeviceTypeModal(false)}>
                <Formik
                    initialValues={{
                        organisation: deviceType.organisation,
                        displayName: deviceType.displayName + " (copy)",
                        name: deviceType.name + "-copy",
                    }}
                    onSubmit={(values, actions) => {
                        copyDeviceType({
                            variables: {
                                id: deviceType.id,
                                newDisplayName: values.displayName,
                                newName: values.name,
                                newOrganisationId: values.organisation?.id,
                            }
                        }).then((result) => {
                            notify.success(t("device-types.copy.success", "Successfully copied device type."))
                            navigate("/deviceTypes/" + result.data.copyDeviceType.id)
                            setShowCopyDeviceTypeModal(false)
                        }, (e) => {
                            actions.setFieldError("global", e);
                            Log.Error("Failed to copy Device Type", e);
                            notify.error(t("device-types.copy.error","Failed to copy DeviceType."), err?.toString())
                        }).finally(() => {
                            actions.setSubmitting(false);
                        })
                    }}>
                    <Form>
                        <SldsInputField name={"displayName"} label={t("device-types.display-name", "Display Name")}
                                        placeholder={t("device-types.display-name-placeholder", "Device Type Name")}
                                        required={true}/>
                        <SldsInputField name={"name"} label={t("device-types.type-name", "Type Name")}
                                        placeholder={t("device-types.type-name-placeholder", "device-type-name")}
                                        required={true}
                                        validate={(value) => /^[a-zA-Z0-9]+[a-zA-Z0-9\-]*[a-zA-Z0-9]+$/.test(value) ? null : t("device-types.validation.only-use", "Only use {{allowed}}", {allowed: "[0-9, a-z, A-Z, -]"})}
                        />
                        <OrganisationLookupField/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>

                        <FormActions>
                            <SubmitButtonField>{t("common.button.copy", "Copy")}</SubmitButtonField>
                            <CancelButtonField onClick={() => setShowCopyDeviceTypeModal(false)}/>
                        </FormActions>
                    </Form>
                </Formik>
            </Modal>
        </TabNavigation>




    </Page>
}

function base64ToBuffer(str){
    str = window.atob(str); // creates a ASCII string
    var buffer = new ArrayBuffer(str.length),
        view = new Uint8Array(buffer);
    for(var i = 0; i < str.length; i++){
        view[i] = str.charCodeAt(i);
    }
    return buffer;
}