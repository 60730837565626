import React, { useEffect, useState } from "react"
import { LoginButton } from "./loginbutton"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import gql from "graphql-tag"
import { useAuthContext } from "../common/context/authContext"
import { useQuery } from "@apollo/client"
import OrganisationSelector from "./organisationSelector"
import OrganizationBreadcrumbs from "./OrganizationBreadcrumbs"

const QUERY_ORGANISATION = gql`
    query ($orgId: ID!) {
        getOrganisation(id: $orgId) {
            id
            name
            logoUrl
        }
    }
`

export const Header = (props) => {
    const auth = useAuthContext()
    let [orgId, setOrgId] = useState(auth.organisationId())
    useEffect(() => {
        auth.onChange(() => {
            setOrgId(auth.organisationId())
        })
    })

    const organisationResult = useQuery(QUERY_ORGANISATION, {
        variables: { orgId: orgId },
    })

    const org = organisationResult?.data?.getOrganisation

    return <div className="slds-global-header slds-grid slds-grid_align-spread">
        <div className="slds-global-header__item">
            <div className="slds-grid" style={{ "paddingLeft": "14px" }}>{/*Logo Alignment with App Launcher Icon*/}
                <Link to={props.logoLinkTo} className="">
                    {props.logo}
                </Link>
                <div className={"slds-p-left--medium"}>
                    <p className="slds-page-header__title slds-align-middle">{org?.name}</p>
                    {auth.hasRole("admin", "org-admin") &&
                        <OrganizationBreadcrumbs />
                    }
                </div>
                {auth.hasRole("admin", "org-admin") && <div><OrganisationSelector /></div>}
            </div>
        </div>
        <div className="slds-global-header__item">
            <LoginButton />
        </div>
    </div>
}

Header.propTypes = {
    "logo": PropTypes.object.isRequired,
    "logoLinkTo": PropTypes.string.isRequired,
}

export default Header