import React, {useState} from "react";
import Button from "../../common/slds/buttons/button";
import {Formik} from "formik";
import {Log} from "../../common/log";
import {Form} from "../../common/ui/form/formik";
import {CancelButtonField, FormActions, SldsInputField, SubmitButtonField} from "../../common/ui/form/formElements";
import {Modal} from "../../common/slds";
import Roles from "../../model/roles";
import {useAuthContext} from "../../common/context/authContext";
import {useNotificationContext} from "../../notifications/notificationContext";
import {useMutation, useQuery} from "@apollo/client";
import gql from "graphql-tag";
import {Accordion, AccordionPanel} from "../../common/ui/accordion";
import ScopedNotification from "../../common/slds/scopedNotifications/scopedNotification";
import DeviceTypeMultiLookupField from "../../components/deviceType/deviceTypeMultiLookupField";
import {useT} from "../../common/i18n";


const MUTATION_CREATE_INPUT_FILTER = gql`
    mutation ($orgId: ID!, $name: String!) {
        integrationInputFilter: createIntegrationInputFilter(orgId: $orgId, name: $name){
            id
        }
    }
`;

const QUERY_INTEGRATION_INPUT_FILTER = gql`
    query ($orgId: ID!) {
        integrationInputFilterList: integrationInputFilterList(orgId: $orgId){
            id
            createdAt
            name
            filter {
                deviceTypeIds
                deviceTypes {
                    id
                    displayName
                }
                deviceTypeTraits
            }
        }
    }`;

const MUTATION_DELETE_INTEGRATION_INPUT_FILTER = gql`
    mutation ($id: ID!) {
        integrationInputFilter: deleteIntegrationInputFilter(id: $id){
            id
        }
    }
`;

const MUTATION_UPDATE_INTEGRATION_INPUT_FILTER = gql`
    mutation ($id: ID!, $input: IntegrationInputFilterInput!) {
        integrationInputFilter: updateIntegrationInputFilter(id: $id input: $input){
            id
        }
    }
`

export const InputFilterTab = () => {
    const t = useT()
    const auth = useAuthContext();
    const notify = useNotificationContext();
    const [modalOpen, setModalOpen] = useState(false);
    const orgId = auth.organisationId();
    const canEdit = auth.hasRole(Roles.ADMIN, Roles.ORG_ADMIN);

    const inputFilterResult = useQuery(QUERY_INTEGRATION_INPUT_FILTER, {
        variables: {
            "orgId": orgId,
        }
    });

    const [createInputFilterMutation] = useMutation(MUTATION_CREATE_INPUT_FILTER, {
        variables: {
            "orgId": orgId,
        },
        refetchQueries: [{
            query: QUERY_INTEGRATION_INPUT_FILTER,
            variables: {
                "orgId": orgId,
            },
        }]
    });

    const [deleteIntegrationInputFilterMutation] = useMutation(MUTATION_DELETE_INTEGRATION_INPUT_FILTER, {
        variables: {},
        refetchQueries: [{
            query: QUERY_INTEGRATION_INPUT_FILTER,
            variables: {
                "orgId": orgId,
            },
        }]
    });

    const [updateIntegrationInputFilterMutation] = useMutation(MUTATION_UPDATE_INTEGRATION_INPUT_FILTER, {
        variables: {},
        refetchQueries: [{
            query: QUERY_INTEGRATION_INPUT_FILTER,
            variables: {
                "orgId": orgId,
            },
        }]
    });


    const inputFilters = inputFilterResult.data?.integrationInputFilterList;


    return <div className="slds-m-horizontal--x-small">
        <p className="slds-m-bottom--medium">{t("integrations.filter.explanation", "Define filter that can be assigned to outgoing integrations to filter what data is being forwarded.")}</p>

        {inputFilters?.length === 0 ? <ScopedNotification theme="light" className="slds-m-bottom--small">{t("integrations.filter.no-filters-yet", "No filters created yet!")}</ScopedNotification> : null}

        <Accordion>
            {inputFilters?.map((f) => {
                return <AccordionPanel key={f.id} id={f.id} summary={f.name}>
                    <Formik
                        initialValues={{
                            ...f,
                        }}
                        initialStatus={{
                            readOnly: true,
                            canEdit: canEdit,
                        }}
                        onSubmit={(values) => {
                            Log.Debug("InputFilterTab.submit", values);
                            return updateIntegrationInputFilterMutation({
                                variables: {
                                    id: f.id,
                                    input: {
                                        name: values.name,
                                        filter: {
                                            deviceTypeIds: values.filter?.deviceTypes?.map((it) => Number(it.id)),
                                            deviceTypeTraits: [],
                                        }
                                    }
                                }
                            }).then(() => {
                                notify.success(t("integrations.filter.notify.updated", "Updated Filter."));
                            }).catch((err) => {
                                notify.error(t("integrations.filter.notify.update-failed", "Failed to update HTTP integration."), err);
                            });
                        }}
                    >
                        <Form>
                            <SldsInputField name={"name"} label={t("integrations.filter.name", "Filter name")} placeholder={t("integrations.filter.placeholder.name", "My Devices")} required={true}/>
                            <DeviceTypeMultiLookupField name={"filter.deviceTypes"} label={t("integrations.filter.device-type", "Filter Device Types")}/>
                            <FormActions>
                                <SubmitButtonField>Save</SubmitButtonField>
                                <CancelButtonField/>
                                <Button variant={"destructive"} iconName={"delete"} onClick={() => {
                                    if (window.confirm(t("integrations.filter.confirm.delete", "Delete filter?"))) {
                                        deleteIntegrationInputFilterMutation({variables: {id: f.id}})
                                            .then(() => {
                                                notify.success(t("integrations.filter.notify.deleted", "Deleted filter."));
                                            })
                                            .catch((err) => {
                                                notify.error(t("integrations.filter.notify.deleted-failed", "Failed to delete filter"), err);
                                            });
                                    }
                                }}>Delete</Button>
                            </FormActions>
                        </Form>
                    </Formik>
                </AccordionPanel>;
            })}
        </Accordion>


        {canEdit ?
            <Button iconName={"add"} iconCategory={"utility"} onClick={() => setModalOpen(true)}>{t("integrations.filter.notify.create-filter-button", "Create Filter")}</Button>
            : null}

        <Modal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)} header={t("integrations.filter.notify.create-filter-header", "Create Filter")}>
            <Formik
                initialValues={{
                    method: "post"
                }}
                onSubmit={(values, actions) => {
                    Log.Debug("InputFilterTab.submit", values);
                    createInputFilterMutation({
                        variables: {
                            name: values.name,
                        }
                    }).then(() => {
                        notify.success(t("integrations.filter.notify.created", "Created filter."));
                        setModalOpen(false);
                    }).catch((err) => {
                        notify.error(t("integrations.filter.notify.create-failed", "Failed to create filter."), err);
                    }).finally(() => {
                        actions.setSubmitting(false);
                    });

                }}
            >
                <Form>
                    <SldsInputField autoFocus={true} name={"name"} label={t("integrations.filter.name", "Filter name")} placeholder={t("integrations.filter.placeholder.name", "My Devices")} required={true}/>
                    <p></p>
                    <FormActions>
                        <SubmitButtonField>{t("common.button.save", "Save")}</SubmitButtonField>
                        <CancelButtonField onClick={() => setModalOpen(false)}/>
                    </FormActions>
                </Form>

            </Formik>
        </Modal>
    </div>;
};