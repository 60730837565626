import {DeviceDatasourceType} from "../../../model/device";
import {Icon} from "../../../common/slds/icons/icon";
import {Tooltip as ReactTooltip} from "react-tooltip";
import React from "react";
import PropTypes from "prop-types";


export function DataSourceLinkStatusIcon(props) {
    const {datasource} = props;

    if (datasource.type === "") {
        return <></>;
    }

    const datasourceName = DeviceDatasourceType[datasource.type].label;

    return <>{datasource.connected ?
        <span data-for="ds-connected-tooltip" data-tip={`${datasourceName} connected`}><Icon data-for="main" data-tip="Datasource connected" name="linked" size="small"/></span> :
        <span data-for="ds-connected-tooltip" data-tip={`${datasourceName} not connected`}><Icon data-for="main" data-tip="Datasource not connected" name="unlinked" size="small"/></span>}
        <ReactTooltip id="ds-connected-tooltip" effect={"solid"}/>
    </>;
}

DataSourceLinkStatusIcon.propTypes = {
    datasource: PropTypes.shape({
        connected: PropTypes.bool.isRequired,
    }).isRequired
};
