import {useEffect, useState} from "react";
import {Log} from "../log";
import _ from "lodash"
import {useDebounce} from "./useDebounce";

// example Filter: myProperty >= 10
// => [{"field": "myProperty", "op": "gte", "value": "10"}]
//filters: [{"field": "myProperty", "op": "gte", "value": "10"}]
export function useFilter(initialFilterTabVisible, initialFilters = [], refetchQuery = null) {
    const [filter, setFilterInternal] = useState(initialFilters)
    const [columnFilters, setColumnFilters] = useState(new Map())
    const [filterTabVisible, setFilterTabVisible]  = useState(initialFilterTabVisible)
    const refetch = useDebounce(refetchQuery, 500)
    useEffect(() => {
            if (refetchQuery) {
                refetch({
                    filter: getGraphqlFilterInput(),
                })
            }
        },
        [filter, columnFilters]
    )

    function reset() {
        setFilterTabVisible(initialFilterTabVisible)
        setFilterInternal(initialFilters)
        setColumnFilters(new Map())
    }

    function setColumnFilter(filterProperty, filter) {
        const newColumnFilters = new Map(columnFilters);
        if (filter != null) {
            newColumnFilters.set(filterProperty, filter)
        } else {
            newColumnFilters.delete(filterProperty)
        }
        setColumnFilters(newColumnFilters)
    }

    function  setFilter(newFilter){
        if (!_.isEqual(filter, newFilter)) {
            setFilterInternal(newFilter);
        }
    }

    function setFilterString(filterString) {
        let newFilter = [];
        try {
            newFilter = JSON.parse(filterString)
        } catch (e) {
            Log.Debug("Failed to parse filterString", filterString, e)
        }
        if (!_.isEqual(filter, newFilter)) {
            setFilterInternal(newFilter);
        }
    }

    function getGraphqlFilterInput() {
       let allFilters = []
       allFilters.push(...filter)
        for (const [, value] of columnFilters) {
            allFilters.push(value)
        }
       return allFilters;
    }

    return {
        filter,
        columnFilters,
        reset,
        setFilter,
        setFilterString,
        setColumnFilter,
        getGraphqlFilterInput,
        filterTabVisible,
        setFilterTabVisible
    }
}