import gql from "graphql-tag"

export const MUTATION_UPDATE_ORGANISATION = gql`
    mutation($orgId: ID!, $organisation: OrganisationInput!){
        updateOrganisation(id: $orgId, input: $organisation) {
            id
            name
            logoUrl
            dataRetentionTime
            maxSubOrganisations
            maxSubOrgDepth
        }}
`
export const QUERY_DEEP_SUBORGANISATION_TREE = gql`
    query($id: ID!) {
        getOrganisationDeepload(id: $id) {
            id
            name
            numDevices
            maxSubOrganisations
            maxSubOrgDepth
            effectiveSubOrgDepth
            numUsers
            parentOrganisation {
                id,
                name,
                maxSubOrgDepth
                effectiveSubOrgDepth
            }
            subOrganisations {
                id
                name
                numDevices
                numUsers
                maxSubOrganisations
                maxSubOrgDepth
                effectiveSubOrgDepth
                parentOrganisation {
                    id,
                    name,
                    maxSubOrgDepth
                    effectiveSubOrgDepth
                }
                subOrganisations {
                    id
                    name
                    numDevices
                    numUsers
                    maxSubOrganisations
                    maxSubOrgDepth
                    effectiveSubOrgDepth
                    parentOrganisation {
                        id,
                        name,
                        maxSubOrgDepth
                        effectiveSubOrgDepth
                    }
                    subOrganisations {
                        id
                        name
                        numDevices
                        numUsers
                        maxSubOrganisations
                        maxSubOrgDepth
                        effectiveSubOrgDepth
                        parentOrganisation {
                            id,
                            name,
                            maxSubOrgDepth
                            effectiveSubOrgDepth
                        }
                        subOrganisations {
                            id
                            name
                            numDevices
                            numUsers
                            maxSubOrganisations
                            maxSubOrgDepth
                            effectiveSubOrgDepth
                            parentOrganisation {
                                id,
                                name,
                                maxSubOrgDepth
                                effectiveSubOrgDepth
                            }
                            subOrganisations {
                                id
                                name
                                numDevices
                                numUsers
                                maxSubOrganisations
                                maxSubOrgDepth
                                effectiveSubOrgDepth
                                parentOrganisation {
                                    id,
                                    name,
                                    maxSubOrgDepth
                                    effectiveSubOrgDepth
                                }
                                subOrganisations {
                                    id
                                    name
                                    numDevices
                                    numUsers
                                    maxSubOrganisations
                                    maxSubOrgDepth
                                    effectiveSubOrgDepth
                                    parentOrganisation {
                                        id,
                                        name,
                                        maxSubOrgDepth
                                        effectiveSubOrgDepth
                                    }
                                    subOrganisations {
                                        id
                                        name
                                        numDevices
                                        numUsers
                                        maxSubOrganisations
                                        maxSubOrgDepth
                                        effectiveSubOrgDepth
                                        parentOrganisation {
                                            id,
                                            name,
                                            maxSubOrgDepth
                                            effectiveSubOrgDepth
                                        }
                                        subOrganisations {
                                            id
                                            name
                                            numDevices
                                            numUsers
                                            maxSubOrganisations
                                            maxSubOrgDepth
                                            effectiveSubOrgDepth
                                            parentOrganisation {
                                                id,
                                                name,
                                                maxSubOrgDepth
                                                effectiveSubOrgDepth
                                            }
                                            subOrganisations {
                                                id
                                                name
                                                numDevices
                                                numUsers
                                                maxSubOrganisations
                                                maxSubOrgDepth
                                                effectiveSubOrgDepth
                                                parentOrganisation {
                                                    id,
                                                    name,
                                                    maxSubOrgDepth
                                                    effectiveSubOrgDepth
                                                }
                                                subOrganisations {
                                                    id
                                                    name
                                                    numDevices
                                                    numUsers
                                                    maxSubOrganisations
                                                    maxSubOrgDepth
                                                    effectiveSubOrgDepth
                                                    parentOrganisation {
                                                        id,
                                                        name,
                                                        maxSubOrgDepth
                                                        effectiveSubOrgDepth
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
export const MUTATION_DELETE_ORGANISATION = gql`
    mutation($id: ID!,) {
        deleteOrganisation(id: $id)
    }`
export const MUTATION_UPDATE_USER_ORG = gql`
    mutation($id: ID!, $input: UserInput) {
        updateUser(id: $id, input: $input) {
            id
            organisation {
                id
                name
            }
        }
    }`