import { useT } from "../../../common/i18n"
import Page from "../../../common/ui/page"
import { Link } from "react-router-dom"
import React, {useRef, useState} from "react"
import {Button, Form, Input, InputRef, Space, Table, Tooltip, Typography} from "antd"
import gql from "graphql-tag"
import { useMutation, useQuery } from "@apollo/client"
import { WmBusKeyItem } from "./WmbusConfigPage"
import { SorterResult } from "antd/es/table/interface"
import { useNotificationContext } from "../../../notifications/notificationContext"
import { Icon } from "../../../common/slds/icons/icon"
import ProgressBar from "../../../common/slds/progressBar/progressBar"
import {ColumnType} from "antd/es/table";
import {SearchOutlined} from "@ant-design/icons";
import {FilterConfirmProps} from "./WmbusKeyImportPage";
import Highlighter from 'react-highlight-words';
import SldsTooltip from "../../../common/slds/tooltip/tooltip";



const ImportStatus = {
    Done: "done",
    Failed: "failed",
}


const CREATE_WMBUS_KEY = gql`mutation($wmbusKey: WmbusKeyInput!){
    createWmbusKey(input: $wmbusKey){
        id
        meterId
    }}
`


const GET_KEYS_FROM_EXCHANGE = gql`
    query ($platformUrl: String!, $token: String!){
        getWmbusKeysFromExchangePlatform(platformUrl: $platformUrl, token: $token) {
            keys {
                meterId
                manufacturer
                encryptionKey
            }
            totalAmountOfKeysReceived
        }

    }
`

type DataIndex = keyof ExchangePlatformKey | "";

interface TableParams {
    sort?: SorterResult<WmBusKeyItem>
    searchedText: string
    searchedColumn: DataIndex
    page: number
    pageSize: number
}


type CreationResult = {
    status: string,
    localId: string
}


const renderStatus = (status) => {
    switch (status) {
        case ImportStatus.Done:
            return <Icon name="check" size={"small"} />
        case "":
            return <div />
        case undefined:
            return <div />
        case null:
            return <div />
        default:
            return <SldsTooltip  content={() => { return status
            }}><Icon name="close" size={"small"} />
            </SldsTooltip>
            // <Icon name="close" size={"small"} />

    }
}

export interface ExchangePlatformKey {
    localId: number
    meterId: string
    manufacturer: string
    encryptionKey: string
    importStatus: string | null
}

export default function KeyExchangePlatformPage() {
    const t = useT()
    const [form] = Form.useForm()
    const notify = useNotificationContext()
    const [exchangePlatformKeys, setExchangePlatformKeys] = useState<ExchangePlatformKey[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [totalCountAtExchange, setTotalCountAtExchange] = useState<number>(0)
    const [importedCnt, setImportedCnt] = useState<number>(-1)
    const [successCnt, setsSuccessCnt] = useState<number>(0)
    const [searchedText, setSearchedText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [errorCnt, setErrorCnt] = useState<number>(0)
    const searchInput = useRef<InputRef>(null);


    const [tableParams, setTableParams] = useState<TableParams>({
        searchedColumn: "",
        searchedText: "",
        page: 1,
        pageSize: 100,
    })


    const [createWmbusKey] = useMutation(CREATE_WMBUS_KEY, {
        // duplicate id's are causing issues when cached ...
        fetchPolicy: "no-cache",
    })

    const keysFromExchange = useQuery(GET_KEYS_FROM_EXCHANGE, {
        // duplicate id's are causing issues when cached ...
        skip: true,
        fetchPolicy: "no-cache",
    })

    const onFinish = (values) => {
        console.log("import keys with: ", values)
        setImportedCnt(-1)
        setsSuccessCnt(0)
        setErrorCnt(0)

        keysFromExchange.refetch({
            platformUrl: values.platformUrl,
            token: values.token,
        }).then((result) => {
            console.log(result)
            setExchangePlatformKeys(result.data.getWmbusKeysFromExchangePlatform.keys.map((key: ExchangePlatformKey, index: number) => {
                return { ...key, localId: index }
            }))
            setTotalCount(result.data.getWmbusKeysFromExchangePlatform.keys.length || 0)
            setTotalCountAtExchange(result.data.getWmbusKeysFromExchangePlatform.totalAmountOfKeysReceived || 0)
            notify.info(t("org.config.wmbus.keyexchange.get_keys_success", "Successfully got keys from exchange platform."))
        }).catch((err) => {
            console.log(err)
            let message = ""
            const messageKey = err?.graphQLErrors[0]?.extensions?.messageKey
            switch (messageKey) {
                case 'exchange_platform_connection_error':
                    message = t("org.config.wmbus.keyexchange.error-keys.exchange_platform_connection_error", "Could not connect to KeyExchange Platform.")
                    break
                case 'exchange_platform_authorization_error':
                    message = t("org.config.wmbus.keyexchange.error-keys.exchange_platform_authorization_error", "Authorization error at KeyExchange Platform. Check used token.")
                    break
                case 'exchange_platform_no_keys_error':
                    message = t("org.config.wmbus.keyexchange.error-keys.exchange_platform_no_keys_error", "KeyExchange Platform returned no Keys.")
                    break
                case 'exchange_platform_status_not_ok_error':
                    message = t("org.config.wmbus.keyexchange.error-keys.exchange_platform_status_not_ok_error", "Failed to get keys from exchange platform. Check console for details.")
                    break
                default:
                    console.log("getWmbusKeysFromExchangePlatform returned unknown error messageKey: ", messageKey)
            }
            if (message !== "") {
                notify.error(message)
            } else {
                notify.error(t("org.config.wmbus.keyexchange.get_keys_failed", "Failed to get keys from exchange platform. Check console for details."))
            }
        })
    }


    const pageChange = (page: number, pageSize: number) => {
        setTableParams({
                ...tableParams,
                page: page,
                pageSize: pageSize,
            },
        )
        return
    }

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchedText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleSearchReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchedText('');
        setSearchedColumn('');
    };

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<ExchangePlatformKey> => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{marginBottom: 8, display: 'block'}}
                />
                <Space>
                    <Button
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        //  style={{minWidth: 90}}
                    >
                        {t("common.button.search", "Search")}
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleSearchReset(clearFilters)}
                        size="small"
                        //     style={{width: 90}}
                    >
                        {t("org.config.wmbus.reset", "Reset")}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
        ),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[searchedText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });


    const columns = [
        {
            title: t("org.config.wmbus.meter_id", "Meter Id"),
            dataIndex: "meterId",
            key: "meterId",
            ...getColumnSearchProps("meterId"),
        },
        {
            title: t("org.config.wmbus.manufacturer", "Manufacturer"),
            dataIndex: "manufacturer",
            key: "manufacturer",
            ...getColumnSearchProps('manufacturer'),
        },
        {
            title: t("org.config.wmbus.encryption_key", "Encryption Key"),
            dataIndex: "encryptionKey",
            key: "encryptionKey",
        },
        {
            title: t("org.config.wmbus.keyexchange.import_status", "Import Status"),
            dataIndex: "importStatus",
            key: "importStatus",
            render: renderStatus,
        },
        {
            title: "Operations",
            dataIndex: "operation",
            render: (_, record: ExchangePlatformKey) => {
                return <Typography.Link onClick={() => {
                    setExchangePlatformKeys(exchangePlatformKeys.filter((key: ExchangePlatformKey) => {
                        return key.localId !== record.localId
                    }))
                }}>
                    {t("org.config.wmbus.keyexchange.remove", "Remove")}
                </Typography.Link>
            },
        },

    ]


    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo)
    }


    const createKeys = () => {
        const promises: Promise<CreationResult>[] = []
        setImportedCnt(0)
        setsSuccessCnt(0)
        setErrorCnt(0)
        let localImportCnt = 0
        let localSuccessCnt = 0
        let localErrorCnt = 0
        const newKeyViewData: ExchangePlatformKey[] = []
        exchangePlatformKeys.forEach((key: ExchangePlatformKey) => {
            const promise = createWmbusKey({
                variables: {
                    wmbusKey: {
                        meterId: key.meterId,
                        manufacturer: key.manufacturer,
                        encryptionKey: key.encryptionKey,
                    },
                },
            }).then(() => {
                localImportCnt++
                setImportedCnt(localImportCnt)
                localSuccessCnt++
                setsSuccessCnt(localSuccessCnt)
                key.importStatus = ImportStatus.Done
                newKeyViewData.push(key)
                return { status: ImportStatus.Done, keyId: key.localId }
            }).catch((err) => {
                console.log("update err:", err)
                localImportCnt++
                setImportedCnt(localImportCnt)
                localErrorCnt++
                setErrorCnt(localErrorCnt)
                key.importStatus = err
                newKeyViewData.push(key)
                return err.resolve({ status: ImportStatus.Failed, keyId: key.localId })
            })

            promises.push(promise)
        })
        Promise.all(promises).then((values) => {
            setExchangePlatformKeys(newKeyViewData)
            const failed = values.filter((v) => v.status === ImportStatus.Failed)
            if (failed.length === 0) { //"Only use {{allowed}}", {allowed: "[0-9, a-z, A-Z, -]"}
                notify.info(t("wmbus-key-import.import-success-notification", "imported keys"))
            } else {
                notify.error(t("wmbus-key-import.import-failed-notification", "error on key import: "))
            }
        })

    }

    return <Page
        trail={[<Link to={window.location.href} key={1}>{t("org.config.nav.wmbus.key-exchange-platform.titel", "wMbus Key Exchange Platform")}</Link>]}
        title={t("org.config.nav.wmbus.key-exchange-platfor.titel", "wMbus Key Exchange Platform")}
    >
        <Form onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              name="keyExchangeImport"
              form={form}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600 }}
              initialValues={{ platformUrl: "https://exchange-platform.app" }}
        >
            <Form.Item
                label="Exchange Platform Url"
                name="platformUrl"
                rules={[{ required: true, message: "Please input target Key Exchange Platform Address!" }]}
            >
                <Input />
            </Form.Item>


            <Form.Item
                label="Token"
                name="token"
                tooltip={<Tooltip title="Token">
                    <span>Go to your Key Exchange Platform Account and create a new token. (Under: My Company - REST API - API Authentication) Paste the token here.</span>
                </Tooltip>}
            >
                <Input />
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <Button type="primary" htmlType="submit">
                    Load Keys from Exchange Platform
                </Button>
            </Form.Item>
        </Form>




        {totalCountAtExchange > 0 && <div>
            <Button type="primary" onClick={createKeys} disabled={importedCnt !== -1}>
                Import {exchangePlatformKeys.length} Keys
            </Button> { totalCountAtExchange > 0 && exchangePlatformKeys.length === 0 ? <div>{t("org.config.wmbus.keyexchange.no_unknown_keys","All keys at Exchange Platform are already known.")}</div> :
            <div>
                {t("org.config.wmbus.keyexchange.irgnored_keys", "{{ignored_amount}} known keys of {{totalCountAtExchange}} keys at exchange ignored.", {ignored_amount: totalCountAtExchange - exchangePlatformKeys.length, totalCountAtExchange: totalCountAtExchange})}
            </div>
        }
        </div>}
        {importedCnt >= 0 ?
            <div>
                <div className="slds-m-top--x-small">Imported ({importedCnt} / {exchangePlatformKeys.length})</div>
                <ProgressBar current={importedCnt} max={exchangePlatformKeys.length} />
                {t("org.config.wmbus.keyexchange.import_success", "Success")}: {successCnt} {t("org.config.wmbus.keyexchange.import_failed", "Failed")}: {errorCnt}
            </div>
            : null
        }

        <Table
            bordered
            dataSource={exchangePlatformKeys}
            columns={columns}
            rowClassName="editable-row"
            rowKey={(record) => record.localId}
            pagination={{
                onChange: pageChange,
                total: totalCount,
                current: tableParams.page,
                pageSize: tableParams.pageSize,
            }}

        />


    </Page>
}
