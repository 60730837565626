import { NotifyUser } from "../common/userNotification"
import ParserForm from "../common/ui/parserForm"
import React from "react"
import { useAuthContext } from "../common/context/authContext"
import { useOutletContext } from "react-router"

const DeviceTypeParserForm = () => {
    const auth = useAuthContext();
    const {updateDeviceType, deviceType } = useOutletContext()
    const canEdit = auth.hasRole("admin") || (deviceType.private && deviceType.organisationId === auth.organisationId() && auth.hasRole("org-admin"));

    return <ParserForm readOnly={!canEdit}
                       parserCode={deviceType.parserCode}
                       hasParserSelection={false}
                       inputCode={"{}"}
                       parserSelection={"custom"}
                       saveParser={(values, actions) => {
                           const {parserCode} = values;


                           updateDeviceType({
                               variables: {
                                   id: deviceType.id,
                                   input: {
                                       parserCode: parserCode
                                   }
                               }
                           }).catch((err) => {
                               NotifyUser.Error("Failed to save parser", err);
                           }).finally(() => {
                               actions.setSubmitting(false);
                           });
                       }}
    />
}

export default DeviceTypeParserForm