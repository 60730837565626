import PropTypes from 'prop-types';
import React from "react";
import './json.css';


function syntaxHighlightJson(json) {
    if (json === undefined) {
        return "undefined";
    }
    if (json === null) {
        return "null";
    }
    if (typeof json != 'string') {
        json = JSON.stringify(json, undefined, 2);
    }
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
        let cls = 'number';
        if (/^"/.test(match)) {
            if (/:$/.test(match)) {
                cls = 'key';
            } else {
                cls = 'string';
            }
        } else if (/true|false/.test(match)) {
            cls = 'boolean';
        } else if (/null/.test(match)) {
            cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
    });
}

const Json = ({json, jsonString, ...props}) => {
    let parsedJson = json;
    jsonString = jsonString || props.children;
    try {
        if (!parsedJson && jsonString) {
            parsedJson = JSON.parse(jsonString);
        }
    } catch (e) {
        return <pre className="json">Failed to parse JSON: {e.message}</pre>;
    }
    return <pre className="json" dangerouslySetInnerHTML={{__html: syntaxHighlightJson(parsedJson)}} {...props}/>;

};

Json.propTypes = {
    json: PropTypes.any,
    jsonString: PropTypes.string,
    children: PropTypes.string,
};

export default Json;