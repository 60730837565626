import React from "react"
import Box from "../common/ui/box";
import {backendUrl} from "../common/helper";
import {useT} from "../common/i18n";


const ApiTab = () => {
    let testUrl = backendUrl()+"/api/mbus?raw=2e44931578563412330333637a2a0020055923c95aaa26d1b2e7493b2a8b013ec4a6f6d3529b520edff0ea6defc955b29d6d69ebf3ec8a&key=0102030405060708090A0B0C0D0E0F11";
    const t = useT();

    return <div className="slds-p-horizontal--medium slds-p-bottom--medium">
        <div className="slds-text-heading--medium slds-m-bottom--medium">{t("wmbus.api.api-usage","API Usage")}</div>

        <Box className="slds-m-bottom--medium">
            <p className="slds-m-bottom--x-small">{t("wmbus.api.limitations-text",
                "The API can be used free of charge for testing purpose with up to 100 calls per day.\nWe can not guarantee 100% uptime of the public endpoint.\n")}


            </p>
            <p className="slds-m-bottom--x-small">{t("wmbus.api.contact-text","If you need a stable endpoint for more than 100 calls per day or on-premise hosting please contact us to ")}<a href="mailto:support@lobaro.de" target="_blank">support@lobaro.de</a>.<br/>
            </p>

        </Box>

        <div>
            <p className="slds-m-bottom--x-small slds-text-title_bold">{t("wmbus.api.example-call","Example URL call")}</p>
            <p className="slds-m-bottom--small"><a href={testUrl} target="_blank">{testUrl}</a></p>
            <div className="slds-text-heading--label slds-m-bottom--small">{t("wmbus.api.parameters","Parameters")}</div>
            <dl className="slds-dl_inline">
                <dt className="slds-dl_inline__label slds-text-title_bold">raw</dt>
                <dd className="slds-dl_inline__detail">URL Encoded hex or Base64 wMbus data with or without CRC</dd>
                <dt className="slds-dl_inline__label slds-text-title_bold">key</dt>
                <dd className="slds-dl_inline__detail">Optional decryption AES-Key</dd>
            </dl>
        </div>

    </div>
};


export default ApiTab;