import React, {useState} from "react";
import {Log} from "../../../common/log";
import {useParams} from "react-router";
import gql from "graphql-tag";
import {useMutation, useQuery} from "@apollo/client";
import DataTable, {Col, EditCellContent, HeaderCol, Row, TableBody, TableHead} from "../../../common/slds/dataTable";
import {Form} from "../../../common/ui/form/formik";
import {FieldArray, Formik, getIn} from "formik";
import {FormActions, SldsSelectField, SubmitButtonField} from "../../../common/ui/form/formElements";
import {useGraphqlLoadingComponent} from "../../../common/graphql";
import Button from "../../../common/slds/buttons/button";
import {useAuthContext} from "../../../common/context/authContext";
import moment from "moment";
import {useNotificationContext} from "../../../notifications/notificationContext";
import ButtonGroup from "../../../common/slds/buttonGroup/ButtonGroup";
import {ConfigPropertyType} from "../../../model/device";
import {base64ToHex} from "../../../common/convert";
import Tooltip from "../../../common/slds/tooltip/tooltip";
import {Icon} from "../../../common/slds/icons/icon";
import ConfigFileImportModal from "./configFileImportModal";
import {useT} from "../../../common/i18n";
import Roles from "../../../model/roles";
import cron from "@lobaro/lobaro-cron-validate";
import {registerOptionPreset} from "@lobaro/lobaro-cron-validate/lib/option";

const QUERY_DEVICE = gql`
    query device($devId: ID!) {
        device(id: $devId) {
            id
            appId
            name
            serial
            description
            addr
            initialConfigRaw
            configuration {
                name
                value
                setValue
                updatedAt
            }
            location {
                lat
                lon
            }
            deviceType {
                id
                configProperties
                deviceTraits
            }
        }
    }
`;

const MUTATION_UPDATE_DEVICE_CONFIG = gql`
    mutation updateConfig($devId: ID!, $input: [DeviceConfigValueInput!]!) {
        updateDeviceConfiguration(devId: $devId, input: $input) {
            id
            configuration {
                name
                value
                setValue
                updatedAt
            }
        }
    }
`;

const MUTATION_SCHEDULE_CONFIG_DOWNLINK = gql`
    mutation scheduleDownlink($devId: ID!) {
        scheduleDeviceConfigUpdate(devId: $devId) {
            id
        }

    }
`;


const MUTATION_REMOVE_VALUE_FROM_CONFIGURATION = gql`
    mutation removeValueFromDeviceConfiguration($devId: ID!, $name: String!) {
        removeValueFromDeviceConfiguration(deviceId: $devId, name: $name) {
            id
        }
    }
`;


const getDeviceConfigProps = (device, propertyInfo) => {
    if (!device) {
        return [];
    }

    let config = propertyInfo.map(pi => {
        return {
            name: pi.name,
            displayName: pi.displayName,
            type: pi.type,
            setValue: null, // Should not be undefined!
        };
    });

    try {
        device.configuration.forEach((val) => {
            let c = config.find(c => c.name === val.name);
            if (c !== undefined) {
                c.value = val.value;
                c.setValue = val.setValue;
                c.updatedAt = val.updatedAt;
            } else {
                // TODO: Note that we do not have schema for this value!
                // Needs special handling in UI?
                config.push({
                    name: val.name,
                    value: val.value,
                    type: val.type,
                    setValue: val.setValue,
                    updatedAt: val.updatedAt,
                });
            }
        });

        const initialConfig = JSON.parse(device.initialConfigRaw)
        Object.keys(initialConfig).forEach((key) => {
                let c = config.find(c => c.name === key);
                if (c !== undefined) {
                    c.initialValue = initialConfig[key];
                } else {
                    config.push({
                        name: key,
                        value: null,
                        type: null,
                        setValue: null, // Should not be undefined!
                        initialValue: initialConfig[key]
                    })
                }
            }
        );


        // avoid undefined "value" in form, else component is uncontrolled
        /*
        configuration = configuration.map(p => {
            if (p.setValue === undefined) {
                p.setValue = "";
            }
            return p;
        });*/

    } catch (e) {
        Log.Error("Failed to parse device propertiesRaw or initialConfigRaw: ", e);
    }
    return config;
};

const hexRegularExpression = /^[0-9a-fA-F]+$/;
const numberRegularExpression = /^[0-9]+$/;

const lobaroDeviceCronPreset = {
    presetId: 'LobaroDeviceConfigCron',
    useSeconds: true,
    useYears: false,
    useAliases: false, // optional, default to false
    useBlankDay: true,
    allowOnlyOneBlankDayField: false,
    mustHaveBlankDayField: false, // optional, default to false
    useLastDayOfMonth: true, // optional, default to false
    useLastDayOfWeek: true, // optional, default to false
    useNearestWeekday: true, // optional, default to false
    useNthWeekdayOfMonth: false, // optional, default to false
    lobaroUseListOfNearestWeekdays: true,
    seconds: {
        minValue: 0,
        maxValue: 59,
        lowerLimit: 0, // optional, default to minValue
        upperLimit: 59, // optional, default to maxValue
    },
    minutes: {
        minValue: 0,
        maxValue: 59,
        lowerLimit: 0, // optional, default to minValue
        upperLimit: 59, // optional, default to maxValue
    },
    hours: {
        minValue: 0,
        maxValue: 23,
        lowerLimit: 0, // optional, default to minValue
        upperLimit: 23, // optional, default to maxValue
    },
    daysOfMonth: {
        minValue: 1,
        maxValue: 31,
        lowerLimit: 1, // optional, default to minValue
        upperLimit: 31, // optional, default to maxValue
    },
    months: {
        minValue: 0,
        maxValue: 12,
        lowerLimit: 0, // optional, default to minValue
        upperLimit: 12, // optional, default to maxValue
    },
    daysOfWeek: {
        minValue: 1,
        maxValue: 7,
        lowerLimit: 1, // optional, default to minValue
        upperLimit: 7, // optional, default to maxValue
    },
    years: {
        minValue: 1970,
        maxValue: 2099,
        lowerLimit: 1970, // optional, default to minValue
        upperLimit: 2099, // optional, default to maxValue
    },
}



const getConfigPropertyInfo = (propertyInfo, prop) => {
    return propertyInfo.find((pi => pi.name === prop.name));
};

function getValueDisplayString(value, propertyInfo) {
    if (propertyInfo?.type === "ByteArray" && value) {
        return hexRegularExpression.test(value) ? value : base64ToHex(value)
    } else {
        return value
    }
}

export default function DeviceConfig() {
    const t = useT();
    const devId = useParams().deviceId;
    const auth = useAuthContext();
    const notify = useNotificationContext();
    const [editCtx, setEditCtx] = useState({});
    const [openFileImportModal, setOpenFileImportModal] = useState(false);
    const [valueFromFile, setValueFromFile] = useState({});

    const gqlResult = useQuery(QUERY_DEVICE, {
        fetchPolicy: "cache-and-network",
        variables: {devId: devId},
    });

    const [updateDeviceConfig] = useMutation(MUTATION_UPDATE_DEVICE_CONFIG, {
        variables: {
            devId: devId,
        }
    });
    const [scheduleConfigDownlink] = useMutation(MUTATION_SCHEDULE_CONFIG_DOWNLINK, {
        variables: {
            devId: devId,
        }
    });
    const [removeConfigValue] = useMutation(MUTATION_REMOVE_VALUE_FROM_CONFIGURATION, {
        variables: {
            devId: devId,
        }
    })


    const loadingError = useGraphqlLoadingComponent(gqlResult);
    if (loadingError) {
        return loadingError;
    }

    const {device} = gqlResult?.data || {};
    const {deviceType} = device;
    const hasRemoteConfig = deviceType.deviceTraits.find(t => t === "remote-config");

    const canEdit = hasRemoteConfig && auth.hasRole(Roles.ADMIN, Roles.ORG_ADMIN, Roles.DEVICE_ADMIN);
    const propertyInfo = JSON.parse(device?.deviceType?.configProperties || "[]") || [];

    const configProps = getDeviceConfigProps(device, propertyInfo);

    return <div>
        <ConfigFileImportModal open={openFileImportModal} setOpen={setOpenFileImportModal} setValueFunction={valueFromFile}/>
        <Formik
            enableReinitialize={true}
            initialValues={{
                configProps: configProps,
            }}
            validate={(values) => {
                let errors = {configProps: {}};
                let properties = values?.configProps
                properties.map((prop) => {
                    switch (prop.type) {
                        case "Int32":
                            if (prop.setValue != null && prop.setValue !== "" && !numberRegularExpression.test(prop.setValue)) {
                                errors.configProps[prop.name] = t("device.config.validation.not-a-number", "{{prop}} needs to be a Number!", {prop: prop.name})
                            }
                            break;
                        case "ByteArray":
                            if (prop.setValue != null && prop.setValue !== "" && (!hexRegularExpression.test(prop.setValue) || !(prop.setValue.length % 2 === 0))) {
                                errors.configProps[prop.name] = t("device.config.validation.not-hex", "{{prop}} needs to be HEX! [0-9a-fA-F] in an even length.", {prop: prop.name})
                            }
                            break;
                        case "Boolean":
                            if (prop.setValue != null && prop.setValue !== "" && prop.setValue !== "false" && prop.setValue !== "true") {
                                errors.configProps[prop.name] = t("device.config.validation.not-bool", "{{prop}} only true, false and empty allowed.", {prop: prop.name})
                            }
                            break;
                        case "CRON":
                           registerOptionPreset('LobaroDeviceConfigCron', lobaroDeviceCronPreset)
                            if (prop.setValue != null && prop.setValue !== "") {
                                const cronResult = cron(prop.setValue, {preset: 'LobaroDeviceConfigCron'})
                                if (cronResult.isError() || !cronResult.isValid()) {
                                    errors.configProps[prop.name] = t("device.config.validation.invalid-cron", "{{prop}}: invalid cron expression.", {prop: prop.name})
                                }
                            }
                    }
                })
                return errors
            }}
            onSubmit={(values) => {
                Log.Debug("submit values:", values);
                const props = values.configProps.map((p) => {
                    return {
                        name: p.name,
                        setValue: (p.setValue === null) ? null : `${p.setValue}`.trim(),
                    };
                });
                Log.Debug("submit props:", props);
                updateDeviceConfig({
                    variables: {
                        input: props,
                    }
                }).then(() => {
                    scheduleConfigDownlink().then(() => {
                        notify.info(t("device.config.notify.updated-config-and-scheduled-downlink", "Updated device config & scheduled downlink"));
                    }).catch((err) => {
                        notify.error(t("device.config.notify.failed-to-schedule-downlink", "Failed to schedule config downlink"), err);
                    });

                }).catch((err) => {
                    notify.error(t("device.config.notify.failed-to-update-config", "Failed to update device config"), err);
                });
            }}
        >
            <Form>
                {canEdit ?
                    <FormActions>
                        <SubmitButtonField>{t("device.config.update-config", "Update config")}</SubmitButtonField>
                    </FormActions> : null}
                <br/>
                <DataTable fixedLayout={false}>
                    <TableHead>
                        <HeaderCol width="50px">{t("device.config.heading-display-name", "Description")}</HeaderCol>
                        <HeaderCol width="50px">{t("device.config.heading-key", "Technical Key")}</HeaderCol>
                        <HeaderCol width="50px">{t("device.config.heading-current-value", "Current Value")}</HeaderCol>
                        <HeaderCol>{t("device.config.heading-target-value", "Target Value")}</HeaderCol>
                        <HeaderCol>&nbsp;</HeaderCol>
                        <HeaderCol>{t("device.config.heading-updated-at", "Updated at")}</HeaderCol>
                        <HeaderCol>{t("device.config.heading-initial-value", "Factory Setting")}</HeaderCol>
                        {canEdit ? <HeaderCol>{t("device.config.heading-remove-value", "Remove from Config")}</HeaderCol> : null}
                    </TableHead>
                    <TableBody>
                        <FieldArray name={"configProps"}>{((arrayHelper) => {
                            const form = arrayHelper.form;
                            const {values} = form;
                            const errors = form.errors
                            //Log.Debug("array formik", arrayHelper.form);

                            const DefaultCrons = {
                                "": {value: "", label: "None"},
                                EVERY_MIN: {value: "0 0/1 * * * *", label: t("device.config.cron.every-minute", "Every minute")},
                                EVERY_10_MIN: {value: "0 0/10 * * * *", label: t("device.config.cron.every-10-minutes", "Every 10 minutes")},
                                EVERY_15_MIN: {value: "0 0/15 * * * *", label: t("device.config.cron.every-15-minutes", "Every 15 minutes")},
                                EVERY_HOUR: {value: "0 0 0/1 * * *", label: t("device.config.cron.every-hour", "Every hour")},
                                EVERY_3_HOUR: {value: "0 0 0/3 * * *", label: t("device.config.cron.every-3-hours", "Every 3 hours")},
                                EVERY_8_HOUR: {value: "0 0 0/8 * * *", label: t("device.config.cron.every-8-hours", "Every 8 hours")},
                                EVERY_12_HOUR: {value: "0 0 0/12 * * *", label: t("device.config.cron.every-12-hours", "Every 12 hours")},
                                EVERY_DAY: {value: "0 0 10 * * *", label: t("device.config.cron.daily-10-am", "Daily at 10am")},
                                EVERY_1ST_15TH: {value: "0 0 12 1,15 * ?", label: t("device.config.cron.monthly-1-and-15", "At the 1st and 15th every month")},
                                EVERY_1ST_3RD: {value: "0 0 12 1,3 * ?", label: t("device.config.cron.monthly-1-and-3", "At the 1st and 3rd every month")},
                                //EVERY_2ND_MONDAY: {value: "0 0 12 ? * 1/14", label: "Every second monday"}, not possible (in cron only) because day of month and day of week don't restrict each other, or may be misinterpreted?
                            };
                            DefaultCrons.asList = function () {
                                return _.keys(DefaultCrons).map(k => {
                                    return DefaultCrons[k];
                                });
                            };

                            return values.configProps.map((p, i) => {
                                const fieldName = `configProps.${i}.setValue`;
                                const field = arrayHelper.form.getFieldProps(fieldName);
                                // TODO: See TODO above, about config values without a schema! Might be undefined then
                                const info = getConfigPropertyInfo(propertyInfo, p);
                                return <Row key={`${p.name}-${i}`}>
                                    <Col>{p.displayName || p.name}{info ? "" : " *"}</Col>
                                    <Col>{p.name}{info ? "" : " *"}</Col>
                                    <Col wrap={true} width={"30%"}>{getValueDisplayString(p.value, info)}</Col>
                                    <Col wrap={true} width={"30%"}>
                                        <EditCellContent
                                            truncate={false} readOnly={!canEdit}
                                            onEdit={() => {
                                                if (editCtx.isEditing) {
                                                    return false; // Do not allow to edit
                                                }

                                                let initialValue = getIn(form.values, fieldName);
                                                setEditCtx({initialValue: initialValue, isEditing: true});
                                            }}
                                            onStopEdit={() => {
                                                setEditCtx({initialValue: null, isEditing: false});
                                            }}
                                            renderInput={({inputRef, stopEdit}) => {
                                                return <><input ref={inputRef} type="text"
                                                                className="slds-input"
                                                                {...field}
                                                                onFocus={() => {
                                                                    // Empty value should be empty string to allow setting values blank
                                                                    // null would be "do not update"
                                                                    if (p.setValue === null) {
                                                                        form.setFieldValue(fieldName, "", false);
                                                                    }
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === "Enter") {
                                                                        e.preventDefault();
                                                                        stopEdit();
                                                                    }
                                                                    if (e.key === "Escape") {
                                                                        e.preventDefault();
                                                                        stopEdit();
                                                                    }
                                                                }}
                                                />
                                                    {p.type === ConfigPropertyType.CRON.value ?
                                                        <SldsSelectField label={t("device.config.use-predefined-cron", "Use predefined CRON")} name={fieldName} options={DefaultCrons.asList()}/>
                                                        : null}
                                                    <div className="slds-p-top--small">
                                                        <ButtonGroup hasSpace={true}>
                                                            <Button iconName={"save"} variant={"brand"} onClick={() => stopEdit()}>{t("common.button.set", "Set")}</Button>
                                                            {p.type === ConfigPropertyType.STRING.value ?
                                                                <Button iconName={"upload"} variant={"brand"}
                                                                        onClick={() => {
                                                                            setOpenFileImportModal(true)
                                                                            setValueFromFile({
                                                                                    form: form,
                                                                                    fieldName: fieldName,
                                                                                }
                                                                            )
                                                                        }}>{t("common.button.import", "Import")}</Button> : null}
                                                            <Button variant={"destructive"} onClick={() => {
                                                                form.setFieldValue(fieldName, null, false);
                                                                stopEdit();
                                                            }}>{t("device.config.do-not-update-button", "Do not update")}</Button>
                                                            <Button variant={"destructive"} onClick={() => {
                                                                form.setFieldValue(fieldName, editCtx.initialValue, false);
                                                                stopEdit();
                                                            }}>{t("common.button.cancel", "Cancel")}</Button>
                                                        </ButtonGroup>
                                                    </div>
                                                </>;
                                            }}>
                                            {p.setValue === null ? <i>{t("device.config.do-not-update-value", "- do not update -")}</i> : p.setValue} {errors?.configProps?.[p.name] ?
                                            <Tooltip scrollable={true} left="-10px" top="-50px"  content={errors?.configProps?.[p.name]}>
                                                <Icon name="warning" size={"x-small"} className="slds-m-right--x-small"/>
                                            </Tooltip> : null
                                        }
                                        </EditCellContent> </Col>
                                    <Col>
                                        {canEdit ?
                                            <Button iconName={"clear"} iconSize={"small"} noBorder={true} onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                form.setFieldValue(fieldName, null, false);

                                            }}/> : null}
                                    </Col>
                                    <Col>{p.updatedAt ? moment(p.updatedAt).format('DD.MM.YYYY HH:mm:ss') : t("device.config.never", "never")}</Col>
                                    <Col wrap={true} width={"30%"}>{p.initialValue}</Col>
                                    {canEdit ? <Col wrap={true} width={"10%"}>
                                    {info ? null :
                                        <Button iconCategory={"utility"} iconName={"delete"} onClick={() => {
                                            removeConfigValue({variables: {name: p.name}}).then(() => {
                                                notify.info(t("device.config.notify.removed-config-value.success", "Removed Config-Value from device"));
                                                void gqlResult.refetch()
                                            }).catch((err) => {
                                                notify.error(t("device.config.notify.removed-config-value.failed", "Failed to remove Config-Value from device"), err);
                                                void gqlResult.refetch()
                                            })
                                        }}>{t("common.button.delete", "Delete")}</Button>}
                                    </Col> : null}
                                </Row>;
                            });
                        })}</FieldArray>


                    </TableBody>
                </DataTable>
                <p>
                    {t("device.config.missing-in-device-type", "* Missing in device type!")}
                </p>
                {canEdit ?
                    <FormActions>
                        <SubmitButtonField>{t("device.config.update-config", "Update config")}</SubmitButtonField>
                    </FormActions> : null}
            </Form>
        </Formik>

    </div>;
}