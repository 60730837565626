//localStorage (set key i18nextLng=LANGUAGE)
import React from "react";
import i18n from "i18next";
import {Log} from "../common/log";
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";

const QUERY_AVAILABLE_LANGUES = gql`query {getAvailableLanguages}`;

export const LanguageSelector = () => {
    let availableLanguages = null
    Log.Info("Selected language: ", i18n.language )
    const gqlResult = useQuery(QUERY_AVAILABLE_LANGUES);

    if (!gqlResult.loading){
        availableLanguages = gqlResult.data?.availableLanguages
    }

    if (availableLanguages) {
        const selectedValue = i18n.language === "de" ? "de" : "en"
        return <select
            id="dropdown"
            onChange={(e) => i18n.changeLanguage(e.target.value)}
            value={selectedValue}>
            <option value="de">DE</option>
            <option value="en">EN</option>
        </select>
    }

    return <select
        id="dropdown"
        onChange={(e) => i18n.changeLanguage(e.target.value)}
        value={i18n.language}
        >
        {gqlResult.data?.getAvailableLanguages?.map((l:string) => {
          return <option key={l} value={l}>{l.toUpperCase()}</option>
        })}
    </select>
}
