import Page from "../../common/ui/page";
import {Link, useNavigate} from "react-router-dom";
import {ButtonGroup} from "@salesforce/design-system-react";
import * as React from "react";
import {useState} from "react";
import {useQuery} from "@apollo/client";
import {QUERY_ACTIVATION_GROUP_LIST} from "../queries";
import GenericDataTable from "../../common/ui/genericDataTable/genericDataTable";
import DataTableColumn from "../../common/ui/data-table/column";
import moment from "moment";
import AddActivationGroupDialog from "./addActivationGroupDialog";
import {usePagination} from "../../common/hooks/usePagination";
import Button from "../../common/slds/buttons/button";
import {useSearch} from "../../common/hooks/useSearch";
import DataTableCell from "../../common/ui/data-table/cell";

const ActivationGroupsListPage = () => {
    const page = usePagination(25);
    const navigate = useNavigate();
    const search = useSearch();
    const [showNewActivationGroupModal, setShowNewActivationGroupModal] = useState(false);

    const activationGroupListResult = useQuery(QUERY_ACTIVATION_GROUP_LIST, {
        variables: {
            page: page.getGraphqlPageInput(),
            search: search.getGraphqlSearchInput(),
        }
    });

    const activationGroups = activationGroupListResult.data?.getActivationGroupList;
    return <Page
        trail={[<Link to="." key={1}>Activation Groups</Link>]}
        title={"Activation Groups"}
        actions={<ButtonGroup><Button iconName={"add"} onClick={() => setShowNewActivationGroupModal(true)}>New Activation Group</Button></ButtonGroup>}
    >
        <AddActivationGroupDialog isOpen={showNewActivationGroupModal} onRequestClose={() => setShowNewActivationGroupModal(false)} onAdded={(id) => navigate("/configuration/activationGroups/" + id)}/>
        <GenericDataTable id={"activation-groups-table"} items={activationGroups} tableConfigDefault={{}} gqlResult={activationGroupListResult} page={page} search={search}>
            <DataTableColumn label="ID" property={"id"}>
                <DataTableCell key="id" render={(props) => (
                    <Link to={`/configuration/activationGroups/${props.item.id}`}>{props.item.id}</Link>
                )}/>
            </DataTableColumn>
            <DataTableColumn label="Name" property="nr">
                <DataTableCell key="id" render={(props) => (
                    <Link to={`/configuration/activationGroups/${props.item.id}`}>{props.item.nr}</Link>
                )}/>
            </DataTableColumn>
            <DataTableColumn label={"Created"} property={"createdAt"} title={"createdAt"}>
                <DataTableCell render={(props) => {
                    return <div className="slds-cell-wrap">{moment(props.item.createdAt).fromNow()} ({moment(props.item.createdAt).format('DD.MM.YYYY HH:mm:ss')})</div>;
                }}/>
            </DataTableColumn>
        </GenericDataTable>
    </Page>;
};

export default ActivationGroupsListPage;
