import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";


export function Icon(props) {
    let {category, name, title, size, colorVariant, className} = props;

    if (category === "extern") {

        return <span className={classNames(
            "slds-icon slds-icon-text-default",
            {
                ["slds-icon_".concat(size)]: size,
            })}
        >
            <img src={name} alt={title}/>
        </span>;
    } else if (category === "streamline") {
        return <span className={classNames(
            "slds-icon slds-icon-text-default",
            {
                ["slds-icon_".concat(size)]: size,
            })

        }>
            <img src={`/static/img/streamline/${name}`}
                 className={classNames({
                     ["slds-icon_".concat(size)]: size,
                 })}
                 alt={title}/>
                </span>;
    }
    //return <SldsIcon {...props}/>;
    if (!name) {
        switch (category) {
        case "utility":
            name = "question";
            break;
        case "action":
            name = "question_post_action";
            break;
        case "custom":
            name = "custom1";
            break;
        case "doctype":
            name = "attachment";
            break;
        case "standard":
            name = "question_feed";
            break;
        }
    }

    const kababCaseName = name ? name.replace(/_/g, '-') : '';

    if (category === "utility" && !colorVariant) {
        // Else utility icons are not visible by default
        colorVariant = "default";
    }

    return <span className={classNames("slds-icon_container",
        "slds-icon-".concat(category, "-").concat(kababCaseName),
        {
            "slds-current-color": colorVariant === 'current',
            "slds-icon_container_circle": category === 'action',
        }, className)} title={title}>
          <svg className={classNames("slds-icon", {
              [`slds-icon-text-${colorVariant}`]: colorVariant && colorVariant !== 'current',
              ["slds-icon_".concat(size)]: size,
          })} aria-hidden="true">
            <use xlinkHref={`/assets/icons/${category}-sprite/svg/symbols.svg#${name}`}></use>
          </svg>
  <span className="slds-assistive-text">Description of icon when needed</span>
</span>;
}

Icon.defaultProps = {
    category: "utility",
    colorVariant: "",
};

Icon.propTypes = {
    /** TODO: Not implemented
     * **Assistive text for accessibility.**
     * This object is merged with the default props object on every render.
     * * `label`: Text that is visually hidden but read aloud by screenreaders to tell the user what the icon means. Naked icons must have assistive text, however, if you also have visible descriptive text with the icon, declare this prop as <code>assistiveText=''</code>.
     */
    assistiveText: PropTypes.shape({
        label: PropTypes.string
    }),

    /**
     * Icon category from [lightningdesignsystem.com/icons/](https://www.lightningdesignsystem.com/icons/)
     * 'extern' for icons from a given URL passed by the "name" property
     * 'streamline' for icons from streamline library
     */
    category: PropTypes.oneOf(['extern', 'streamline', 'action', 'custom', 'doctype', 'standard', 'utility']).isRequired,

    /**
     * CSS classes that are applied to the span.
     */
    className: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),

    /**
     * Icon color variants
     * empty is a valid variant, it makes category "standard" Icons white for example.
     */
    colorVariant: PropTypes.oneOf(['', 'default', 'error', 'light', 'warning', 'success', 'current']),

    /**
     * A custom SVG object to use instead of the supplied SLDS icons, look in `design-system-react/icons` for examples and syntax.
     */
    icon: PropTypes.object,

    /**
     * Setting `inverse` to true will switch the color of the icon: light to dark, dark to light.
     */
    inverse: PropTypes.bool,

    /**
     * Name of the icon. Visit <a href='http://www.lightningdesignsystem.com/resources/icons'>Lightning Design System Icons</a> to reference icon names.
     */
    name: PropTypes.string,

    /**
     * Url to the icon. This will override any global icon settings
     */
    path: PropTypes.string,

    /**
     * Background theme color for the icon. **Only compatible with icon category `standard`**
     */
    productTheme: PropTypes.oneOf(['global-setup', 'service-cloud', 'industry-cloud', 'sales-cloud', 'commerce-cloud', 'community-cloud', 'marketing-cloud', 'quip']),

    /**
     * Size of the icon. Visit [lightningdesignsystem.com/components/icons/#flavor-sizes](https://www.lightningdesignsystem.com/components/icons/#flavor-sizes)
     */
    size: PropTypes.oneOf(['xx-small', 'x-small', 'small', 'medium', 'large']),

    /**
     * Custom styles to be passed to the SVG. Could be used to change icon or background color.
     */
    style: PropTypes.object,

    /**
     * Title attribute for the icon container
     */
    title: PropTypes.string
};