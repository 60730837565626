import {Log} from "../common/log";
import {NotifyUser} from "../common/userNotification";
import {GenericDataTableConfigForm} from "../common/ui/genericDataTable/genericDataTableConfigForm";
import {CancelButtonField, FormActions, SubmitButtonField} from "../common/ui/form/formElements";
import {Formik} from "formik";
import * as React from "react";
import {useMutation, useQuery} from "@apollo/client";
import {MUTATE_UPDATE_DEVICE_TYPE, QUERY_DEVICE_TYPE} from "./queries";
import {useGraphqlLoadingComponent} from "../common/graphql";
import {useAuthContext} from "../common/context/authContext";
import {ParsedDataDetailComponent} from "../domain/traits/traits";
import GenericDataTable from "../common/ui/genericDataTable/genericDataTable";
import WmbusDataRecordsSelector from "./WmbusDataRecordsSelector";
import {useT} from "../common/i18n";
import {useNotificationContext} from "../notifications/notificationContext";
import {useParams} from "react-router";

const DataTableConfigTab = () => {
    const t = useT();
    const auth = useAuthContext();
    const deviceTypeId = useParams().id; // match.params.id // better -> match.params.deviceTypeId
    const notify = useNotificationContext();

    const deviceTypeResult = useQuery(QUERY_DEVICE_TYPE, {
        variables: {
            id: deviceTypeId
        }
    });

    const [updateDeviceType] = useMutation(MUTATE_UPDATE_DEVICE_TYPE, {
        variables: {id: deviceTypeId},
        refetchQueries: [{
            query: QUERY_DEVICE_TYPE,
            variables: {
                id: deviceTypeId,
            }
        }]

    });


    const prefixCols = [{
        heading: t("device-type.data-table-config.heading.created-at", "Received"),
        csvFormat: "{{date createdAt}}",
        cell: {
            format: "{{date createdAt}}",
        }
    },
        {
            heading: t("device-type.data-table-config.heading.device", "Device"),
            csvFormat: "{{#if device.name}}{{device.name}}{{else}}- no name -{{/if}}",
            cell: {
                format: "{{#if device.name}}{{device.name}}{{else}}- no name -{{/if}}",
                href: "#/organisation/devices/{{device.id}}/device-data",
            }
        },
        {
            heading: t("device-type.data-table-config.heading.address", "Address"),
            csvFormat: "{{device.addr}}",
            cell: {
                format: "{{device.addr}}",
                href: "#/organisation/devices/{{device.id}}/device-data",
            }
        }];

    const loadingHW = useGraphqlLoadingComponent(deviceTypeResult);
    if (loadingHW) {
        return loadingHW;
    }
    const deviceType = deviceTypeResult.data.deviceType;
    const canEdit = auth.hasRole("admin") || (deviceType.private && deviceType.organisationId === auth.organisationId() && auth.hasRole("org-admin"));

    return <> <Formik
        initialValues={(deviceType.dataTableConfigRaw && JSON.parse(deviceType.dataTableConfigRaw)) || {}}
        enableReinitialize={true}
        initialStatus={{
            readOnly: true,
            canEdit: canEdit
        }}
        onSubmit={(values, actions) => {
            Log.Debug("SUBMIT", values);
            updateDeviceType({
                variables: {
                    id: deviceType.id,
                    input: {
                        dataTableConfig: JSON.stringify(values),
                    }
                }
            }).catch((err) => {
                NotifyUser.Error(t("device-type.table-config.update-failed", "Failed to save Table Config"), err);
            }).finally(() => {
                actions.setSubmitting(false);
            });
        }}
        render={formik => {
            return <>
                <GenericDataTableConfigForm
                    id={"generic-table-config-form"}
                    formik={formik}
                    onCancel={() => {
                        Log.Debug("Cancel")
                    }}
                />
                <FormActions>
                    <SubmitButtonField formId={"generic-table-config-form"}/>
                    <CancelButtonField/>
                </FormActions>
            </>;
        }}/>
        <div className="slds-text-heading--medium slds-m-bottom--small">{t("device-type.data-table-config.table-preview", "Table Preview")}</div>
        <GenericDataTable
            id={"parsed-data-table"}
            fixedLayout={false}
            tableConfigDefault={deviceType.dataTableConfigRaw && JSON.parse(deviceType.dataTableConfigRaw)}
            handleTableConfigSave={(values) => {
                return updateDeviceType({
                    variables: {
                        input: {
                            dataTableConfig: JSON.stringify(values),
                        }
                    }
                }).then(() => {
                    notify.info(t("device-type.data-table-config.notify.updated", "Data Table Config for Device Type updated."))
                }).catch((err) => {
                    notify.error(t("device-type.table-config.update-failed", "Failed to save Table Config"), err);
                });
            }}
            items={extractExampleTelegram(deviceType) ? [extractExampleTelegram(deviceType)] : []}
            prefixCols={prefixCols}
            renderDetails={(item) => <ParsedDataDetailComponent traits={deviceType.deviceTraits} data={item}/>}
        />
        {deviceType.deviceTraits.includes("wmbus-data") && extractExampleTelegram(deviceType) ?
            <WmbusDataRecordsSelector mbus={extractExampleTelegram(deviceType).data.mbus} parseError={extractExampleTelegram(deviceType).data.mbus} deviceType={deviceType}/>
            : null
        }

    </>
};

function extractExampleTelegram(deviceType) {
    let dataTableConfig = deviceType.dataTableConfigRaw && JSON.parse(deviceType.dataTableConfigRaw)
    if (dataTableConfig && dataTableConfig.previewTelegram) {
        return JSON.parse(dataTableConfig.previewTelegram)
    }
    return null;
}

export default DataTableConfigTab