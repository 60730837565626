import {Log} from "../common/log";
import {NotifyUser} from "../common/userNotification";
import {GenericDataTableConfigForm} from "../common/ui/genericDataTable/genericDataTableConfigForm";
import {CancelButtonField, FormActions, SubmitButtonField} from "../common/ui/form/formElements";
import {Formik} from "formik";
import * as React from "react";
import {useMutation, useQuery} from "@apollo/client";
import {MUTATE_UPDATE_DEVICE_TYPE, QUERY_DEVICE_TYPE} from "./queries";
import {useGraphqlLoadingComponent} from "../common/graphql";
import {useAuthContext} from "../common/context/authContext";
import {useT} from "../common/i18n";
import {useParams} from "react-router";

const DeviceTableConfigTab = () => {
    const t = useT();
    const auth = useAuthContext();
    const deviceTypeId = useParams().id;

    const deviceTypeResult = useQuery(QUERY_DEVICE_TYPE, {
        variables: {
            id: deviceTypeId
        }
    });

    const [updateDeviceType] = useMutation(MUTATE_UPDATE_DEVICE_TYPE, {
        variables: {id: deviceTypeId},
        refetchQueries: [{
            query: QUERY_DEVICE_TYPE,
            variables: {
                id: deviceTypeId,
            }
        }]
    });


    const loadingHW = useGraphqlLoadingComponent(deviceTypeResult);
    if (loadingHW) {
        return loadingHW;
    }
    const deviceType = deviceTypeResult.data.deviceType;
    const canEdit = auth.hasRole("admin") || (deviceType.private && deviceType.organisationId === auth.organisationId() && auth.hasRole("org-admin"));

    Log.Debug("deviceType", deviceType);

    return <Formik
        initialValues={(deviceType.deviceTableConfigRaw && JSON.parse(deviceType.deviceTableConfigRaw)) || {}}
        enableReinitialize={true}
        initialStatus={{
            readOnly: true,
            canEdit: canEdit
        }}
        onSubmit={(values, actions) => {
            Log.Debug("SUBMIT", values);
            updateDeviceType({
                variables: {
                    id: deviceType.id,
                    input: {
                        deviceTableConfig: JSON.stringify(values),
                    }
                }
            }).catch((err) => {
                NotifyUser.Error(t("device-type.table-config.update-failed", "Failed to save Table Config"), err);
            }).finally(() => {
                actions.setSubmitting(false);
            });
        }}
        render={formik => {
            return <>
                <GenericDataTableConfigForm
                    id={"generic-table-config-form"}
                    formik={formik}
                    onCancel={() => {
                        Log.Debug("Cancel")
                    }}
                />
                <FormActions>
                    <SubmitButtonField formId={"generic-table-config-form"}/>
                    <CancelButtonField/>
                </FormActions>
            </>;
        }}/>
};

export default DeviceTableConfigTab