import React from "react";
import {CustomPropTypes} from "../../propTypes/customPropTypes";

export function TileDetail(props) {
    const {children} = props;
    return <div className="slds-tile__detail">
        {children}
    </div>
}



TileDetail.propTypes = {
    children: CustomPropTypes.children,
};

export default function Tile(props) {
    const {children} = props;
    return <article className="slds-tile slds-hint-parent">
        {children}
    </article>
}

Tile.propTypes = {
    children: CustomPropTypes.children,
};