import {PageHeader, SLDSPageHeaderControl} from "@salesforce/design-system-react";
import React from "react";
import PropTypes from 'prop-types';
import ErrorBoundary from "../../common/ui/errorBoundary";
import classNames from "classnames";
import {CustomPropTypes} from "../propTypes/customPropTypes";

export default function Page(props) {
    const {children, title, actions, controls, info, trail, className, withPadding} = props;

    return <div className={classNames(className)}>
        <PageHeader label=""
                    onRenderActions={actions ? () =>
                        <SLDSPageHeaderControl>{actions}</SLDSPageHeaderControl> : undefined}
                    onRenderControls={controls ? () =>
                        <SLDSPageHeaderControl>{controls}</SLDSPageHeaderControl> : undefined}
                    title={title}
                    trail={trail ? trail : undefined}
                    variant="object-home"
                    info={info ? info : undefined}
            // details are not supported by object-home
            //details={details ? details : undefined}
                    className="slds-has-bottom-magnet"
        />


        <div className={classNames("slds-card slds-has-top-magnet slds-p-top--small", {
            "slds-p-around--x-small": withPadding,
        })}>
            <ErrorBoundary>
                {children}
            </ErrorBoundary>
        </div>
    </div>;
}
Page.propTypes = {
    children: PropTypes.any.isRequired,
    title: CustomPropTypes.children,
    trail: PropTypes.array.isRequired, // We always want a breadcrumb!,
    actions: PropTypes.element,
    controls: PropTypes.element,
    info: PropTypes.any,
    className: PropTypes.string,
    // Add padding for content
    withPadding: PropTypes.bool,
};

