import {Link, useNavigate} from "react-router-dom";
import Page from "../../../common/ui/page";
import React, {useState} from "react";
import {useAuthContext} from "../../../common/context/authContext";
import {OrganisationUserList} from "../../../components/organisation/OrganisationUserList";
import {useT} from "../../../common/i18n";

import {ButtonGroup} from "@salesforce/design-system-react";
import Button from "../../../common/slds/buttons/button";
import AddUserDialog from "../../../components/user/addUserDialog";


export const OrganisationUsersPage = () => {
    const t = useT();
    const auth = useAuthContext();
    const navigate = useNavigate();
    const [showNewUserModal, setShowNewUserModal] = useState(false);

    return <Page
        trail={[<Link to={window.location.href} key={1}>{t("org.config.nav.users", "Users")}</Link>]}
        title={t("org.config.users.title", "Users")}
        actions={<ButtonGroup><Button iconName={"add"} onClick={() => setShowNewUserModal(true)}>Create
            User</Button></ButtonGroup>}>

        <AddUserDialog isOpen={showNewUserModal} onRequestClose={() => setShowNewUserModal(false)} onAdded={(user) => {
            navigate(`/organisation/config/users/${user.id}`);
        }}/>

      <OrganisationUserList organisationId={auth.organisationId()} basePath={"/organisation/config/users/"} />
  </Page>;
}