import React, {useState} from "react";
import {Log} from "../../log";
import {InputButton} from "../../slds/buttons/button";
import {useT} from "../../i18n";


function extractValueFromFilterHook(filterHook, filterProperty) {

    let val = filterHook?.columnFilters?.get(filterProperty)?.value

    if (!val) {
        return null
    }

    let first = val.slice(0,1)
    let last = val.slice(-1)

    if (first === "%" && last === "%") {
        val = val.slice(1, - 1)
    } else if (first === '%') {
        val = val.slice(1)
    } else  if (last === '%')  {
        val = val.slice(0, - 1)
    }

    return  val
}

export function ColumnTextFilter(props) {
    //todo search/filter from and use it for update of search
    const filterHook = props.filterHook
    const filterProperty = props.property

    const value = extractValueFromFilterHook(filterHook, filterProperty)
    let [currentValue, setCurrentValue] = useState(value)


    //[{"field": "a", "op": "lte", "value": "123"}]
    const updateSearch = (filterHook, filterProperty, filterText) => {
        if (filterText == null || filterText === ""){
            filterHook.setColumnFilter(filterProperty, null);
            return
        }
        filterText = filterText.trim()
        let searchVal
        if (!filterText || filterText === "*" || filterText === "**") {
            filterHook.setColumnFilter(filterProperty, null);
            return
        }
        let first = filterText.slice(0,1)
        let last = filterText.slice(-1)
        Log.Debug("First: ", first, " Last: ", last)
        if (first === '*' && last === '*') {
            searchVal = "%" + filterText.slice(1, - 1) + "%"
        } else if (first === '*') {
            searchVal = "%" + filterText.slice(1)
        } else  if (last === '*')  {
            searchVal = filterText.slice(0, - 1) + "%"
        } else {
            searchVal = "%" + filterText + "%"
        }
        Log.Debug("Filter Value: ", searchVal)
        let newFilter = {
            "field": filterProperty,
            "op": "ilike",
            "value": searchVal
        }
        filterHook.setColumnFilter(filterProperty, newFilter);
    }

    return <div className="slds-form-element">
        <div className="slds-form-element__control slds-input-has-icon slds-input-has-icon_right">
        <input type="search" className="slds-input slds-combobox__input"
                  style={value ? {background : "#e7f3fe"} : {}}
                  id="combobox-id-1"
                  aria-autocomplete="list"
                  aria-controls="listbox-id-1"
                  autoComplete="off"
                  onChange={(event) => {
                     setCurrentValue(event.target.value)
                  }}
                  onBlur={() => {
                      updateSearch(filterHook, filterProperty, currentValue);
                  }}
                  onKeyUp={(e) => {
                      if (e.key === "Enter"){
                          updateSearch(filterHook, filterProperty, currentValue);
                      }
                  }}
                  value={currentValue || ""}  />
            {value ?
            <InputButton iconPosition={"right"} iconName={"clear"} onClick={() => updateSearch(filterHook, filterProperty, "")}/>
            : null}
        </div>
     </div>

}




export function ColumnBooleanFilter(props) {
    //todo search/filter from and use it for update of search
    const t = useT()
    const filterHook = props.filterHook
    const filterProperty = props.property
    const [currentValue, setCurrentValue] = useState(filterHook?.columnFilters?.get(filterProperty)?.value);


    //[{"field": "a", "op": "lte", "value": "123"}]
    const updateSearch = (filterHook, filterProperty, filterValue) => {
        setCurrentValue(filterValue)
        if (!filterValue){
            filterHook.setColumnFilter(filterProperty, null);
            return
        }

        let newFilter = {
            "field": filterProperty,
            "op": "eq",
            "value": filterValue
        }
        filterHook.setColumnFilter(filterProperty, newFilter);
    }

    return  <select className="slds-select" id={filterProperty} value={currentValue} onChange={(event) =>
        updateSearch(filterHook, filterProperty, event.target.value)}   style={currentValue ? {background : "#e7f3fe"} : {}}>
            <option key={"null"} value={""}></option>
            <option key={"true"} value={true} label={t("common.bool.yes", "yes")}>{t("common.bool.yes", "yes")}</option>
            <option key={"false"} value={false} label={t("common.bool.no", "no")}>{t("common.bool.no", "no")}</option>
        </select>

}
