import gql from "graphql-tag";
import React, {useContext} from "react";
import {AppContext} from "../../appPage";
import Page from "../../../common/ui/page";
import HeaderListControls from "../../../common/ui/hederListControls";
import {useQueryWithLoading} from "../../../common/graphql";
import GenericDataTable from "../../../common/ui/genericDataTable/genericDataTable";
import {Log} from "../../../common/log";
import {Link} from "react-router-dom";
import {usePagination} from "../../../common/hooks/usePagination";
import {useSort} from "../../../common/hooks/useSort";
import {useFilter} from "../../../common/hooks/useFilter";


const QUERY_PARSED_DATA = gql`
    query getData($appId: ID!, $page:PaginationInputType, $filter:[FilterInputType!], $sort: SortInputType) {
        appInstance(id:$appId) {
            id
            appId
            configRaw
            name
            type
        }
        parsedData(appId: $appId, page: $page, filter: $filter, sort: $sort) {
            id
            createdAt
            dataRaw
            type
            time
            device {
                id
                addr
                description
            }
            datasource {
                id
                name
            }
        }
    }
`;


function tableConfig(appId, appConfig) {
    let tableConfig = {};

    if (typeof appConfig === "string") {
        appConfig = JSON.parse(appConfig);
    }

    if (appConfig && appConfig.ui && appConfig.ui.parsedDataTable) {
        tableConfig = appConfig.ui.parsedDataTable;
    } else {
        // Default columns
        tableConfig.cols = [
            {
                "heading": "Time",
                "width": "10rem",
                "cell": {
                    "format": "{{date time 'DD.MM.YYYY HH:mm:ss'}}"
                }
            },
            {
                "heading": "Datasource",
                "width": "8rem",
                "cell": {
                    "format": "{{datasource.name}}"
                }
            },
            {
                "heading": "Device",
                "width": "10rem",
                "cell": {
                    "href": `#/app/${appId}/devices/{{device.id}}/overview`,
                    "format": "{{device.addr}}"
                }
            },
            {
                "heading": "Description",
                "cell": {
                    "format": "{{device.description}}"
                }
            }
        ]
    }

    return tableConfig
}

// TODO: Build generic view for details, including custom view per datasource
export default function ParsedDataPage() {
    const app = useContext(AppContext);
    const filters = useFilter(false, []);
    const page = usePagination();
    const sort = useSort();

    Log.Debug("DeviceDataPage.app", app);

    const [queryParsedDataResult, loading] = useQueryWithLoading(QUERY_PARSED_DATA, {
        fetchPolicy: "cache-and-network",
        variables: {
            appId: app.id,
            filter: filters.getGraphqlFilterInput(),
            page: page.getGraphqlPageInput(),
            sort: sort.getGraphqlSortInput(),
        }
    });

    if (loading) {
        return loading;
    }

    const data = queryParsedDataResult.data;

    page.setPageItemCount(data.parsedData ? data.parsedData.length : 0);

    Log.Debug("App: ", app);
    return <Page title={"Data"}
                 actions={<HeaderListControls/>}
                 trail={[<Link to={"."} key={1}>Data</Link>]}
    >
        <GenericDataTable
            id={"parsed-data-table"}
            tableConfigName={"parsed-data"}
            tableConfigDefault={tableConfig(app.id, data.appInstance.configRaw)}
            items={data.parsedData.map((d) => {
                return {
                    ...d,
                    data: JSON.parse(d.dataRaw)
                };
            })}
            gqlResult={queryParsedDataResult}
            sort={sort}
            page={page}
            filters={filters}
        />
    </Page>
}