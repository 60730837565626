import gql from "graphql-tag";

export const QUERY_APPLICATIONS = gql`
    query($orgId: ID, $page: PaginationInputType, $search: String){
        appInstances(page: $page, orgId: $orgId, search: $search) {
            id
            appId
            name
            type
            organisation {
                id
                name
            }
        }
    }
`;

export const QUERY_APPLICATION = gql`
    query($appId: ID!){
        appInstance(id: $appId) {
            id
            appId
            name
            type
            logo
            organisation {
                id
                name
            }
        }
    }
`;


export const MUTATE_CREATE_APP = gql`
    mutation ($newApp: AppInstanceInput!){
        createApplication(input: $newApp) {
            id
            name
            appId
        }
    }
`;

export const MUTATE_DELETE_APP = gql`
    mutation ($appId: ID!){

        deleteApplication(id: $appId) {
            id
            name
            appId
        }
    }
`;
export const MUTATE_UPDATE_APP = gql`
    mutation ($appId: ID!, $input: AppInstanceInput!){

        updateApplication(id: $appId, input: $input) {
            id
            name
            appId
        }
    }
`;

export const QUERY_CRON_LOG_LIST = gql`
    query ($filter: [FilterInputType!], $page: PaginationInputType, $sort: SortInputType) {
        getCronLogList( filter: $filter, page: $page, sort:  $sort) {
            errorOccurred
            executionParams
            executionResult
            createdAt
            endTime
            id
            jobName
            startTime
            updatedAt
        }
    }
`;


export const QUERY_HARDWARE_LIST = gql`
    query ($page: PaginationInputType, $search: String, $idNotIn: [ID!]) {
        devices(page: $page, sort: {field: "id", direction: "DESC"}, search: $search, idNotIn: $idNotIn) {
            id
            addr
            name
            description
            app {
                id
                name
            }
            organisation {
                id
                name
            }
            deviceType {
                id
                displayName
            }
            propertiesRaw
            initialConfigRaw
            serial
        }
    }`;

export const MUTATE_UPDATE_DEVICE = gql`
    mutation($id: ID!, $input: DeviceInput!) {
        updateDevice(id: $id, input: $input) {
            id
            name
            description
            addr
            activationCode
            initialConfigRaw
            deviceType {
                id
                displayName
                configProperties
            }
            app {
                id
                name
            }
            organisation {
                id
                name
            }
            comment
            firmwareVersion
        }
    }`;

export const MUTATE_CREATE_DEVICE = gql`
    mutation($input: DeviceInput!) {
        createDevice(input: $input) {
            id
            name
            description
            addr
            activationCode
            initialConfigRaw
            deviceType {
                id
                displayName
                configProperties
            }
            app {
                id
                name
            }
            comment
            firmwareVersion
        }
    }`;

export const QUERY_ACTIVATION_GROUP_LIST = gql`
    query ($page: PaginationInputType, $search: String) {
        getActivationGroupList(page: $page, sort: {field: "id", direction: "DESC"}, search: $search) {
            id
            nr
            createdAt
        }
    }`;

export const MUTATE_CREATE_ACTIVATION_GROUP = gql`
    mutation($input: ActivationGroupInput) {
        createActivationGroup(input: $input) {
            id
        }
    }`;

export const MUTATE_UPDATE_ACTIVATION_GROUP = gql`
    mutation($id: ID!, $input: ActivationGroupInput) {
        updateActivationGroup(id: $id, input: $input) {
            id
        }
    }`;


export const QUERY_FILES = gql`
    query($filter: [FilterInputType!], $page: PaginationInputType, $sort: SortInputType, $search: String, $pathPrefix: String, $mimeType: String){
        getFileList(filter: $filter, page: $page, sort:  $sort, search: $search, pathPrefix: $pathPrefix, mimeType: $mimeType) {
            id
            createdAt
            updateAt
            name
            path
            mimeType      
        }     
      
    }
`;

export const MUTATE_DELETE_FILE = gql`
    mutation($id: ID!) {
        deleteFileById(id: $id) {
            id
            name
        }
    }`;

export const MUTATION_UPLOAD_FIRMWARE = gql`
    mutation($file: File!){
        uploadFirmware(file: $file) {
            id
            name
         
        }}
`;