import React from "react"
import { Link, Outlet } from "react-router-dom"
import Page from "../../common/ui/page"
import { Tab, TabNavigation } from "../../common/ui/tabNavigation"
import { useT } from "../../common/i18n"


export const IntegrationsPage = () => {
    const t = useT();

    return <Page
        title={t("integrations.titel","Integrations")}
        trail={[<Link to={`.`} key={1}>{t("integrations.titel","Integrations")}</Link>]}
    >
        <TabNavigation tabs={[
            <Tab key={"filter"} to={"filter"}>{t("integrations.nav.filter", "Filter")}</Tab>,
            <Tab key={"rest"} to={"rest"}>{t("integrations.nav.rest", "REST")}</Tab>,
            <Tab key={"http"} to={"http"}>{t("integrations.nav.http", "HTTP")}</Tab>,
            <Tab key={"mqtt"} to={"mqtt"}>{t("integrations.nav.mqtt", "MQTT")}</Tab>,
            <Tab key={"sftp"} to={"sftp"}>{t("integrations.nav.sftp", "SFTP")}</Tab>,
            <Tab key={"lorawan"} to={"lorawan"}>{t("integrations.nav.lorawan", "LoRaWAN")}</Tab>,
        ].filter((t => t != null))}>
            <Outlet/>
        </TabNavigation></Page>;
};