import * as React from "react";
import {useState} from "react";
import GenericDataTable from "../../../common/ui/genericDataTable/genericDataTable";
import gql from "graphql-tag";
import * as log from "../../../common/log";
import {Log} from "../../../common/log";
import {useMutation, useQuery} from "@apollo/client";
import {usePagination} from "../../../common/hooks/usePagination";
import {useSort} from "../../../common/hooks/useSort";
import {useCsvExport} from "../../../common/hooks/useCsvExport";
import {NotifyUser} from "../../../common/userNotification";
import {ParsedDataDetailComponent} from "../../../domain/traits/traits";
import {useFilter} from "../../../common/hooks/useFilter";
import {DEFAULT_DATA_TABLE_CONFIG} from "../../../deviceTypes/deviceTypesDetailPage";
import {MUTATE_UPDATE_DEVICE_TYPE} from "../../../deviceTypes/queries";
import {useT} from "../../../common/i18n";
import Spinner from "../../../common/slds/spinner/spinner";
import {useParams} from "react-router";


const QUERY_PARSED_DATA_DEVICE = gql`
    query($devId: ID!, $page:PaginationInputType, $filter:[FilterInputType!], $sort: SortInputType){
        parsedData(devId: $devId, filter: $filter, page: $page, sort: $sort){
            id
            createdAt
            time
            type
            dataRaw
            datasource {
                id
                name
            }
            sensorDataId
        }
    }
`;
const QUERY_DEVICE_TABLE_CONFIG = gql`
    query device($devId: ID!) {
        device(id: $devId) {
            addr
            id
            name
            description
            configRaw
            propertiesRaw
            deviceType {
                id
                name
                dataTableConfigRaw
                deviceTraits
            }
            app {
                id
                configRaw
            }
        }
    }
`;

export default function DeviceParsedData() {
    const t = useT();
    const ninetyDays = 1000*60*60*24*90
    const devId = useParams().deviceId;
    const [deviceType, setDeviceType] = useState(null)

    const pageSize = 100;

    const deviceQueryResult = useQuery(QUERY_DEVICE_TABLE_CONFIG, {
        fetchPolicy: "cache-first", //maybe not cache the result so tableconfig gets updated immediately?
            variables: {
            devId: devId,
        },
        onCompleted: data => setDeviceType(data.device.deviceType)
    });

    let initialFilter = [{"field": 'createdAt', "op": "lte", "value": new Date(Date.now())},
        {"field": 'createdAt', "op": "gte", "value": new Date(Date.now()-ninetyDays)}]
    const dataQueryResult = useQuery(QUERY_PARSED_DATA_DEVICE, {
        fetchPolicy: "cache-and-network",
        //pollInterval: page.currentPage() === 1 ? 5000 : 0,
        skip: false,
        variables: {
            devId: devId,
            page: {
                offset: 0,
                limit: pageSize,
            },
        }
    });

    const filters = useFilter( true, initialFilter, dataQueryResult.refetch)
    const sort = useSort(null, dataQueryResult.refetch);
    const page = usePagination(pageSize, dataQueryResult.refetch);

    const csvExport = useCsvExport(QUERY_PARSED_DATA_DEVICE, {
        variables: {
            devId: devId,
            sort: sort.getGraphqlSortInput(),
            filter: filters.getGraphqlFilterInput()
        },
        dataExtractor: d => d.data.parsedData.map(parsedDataMapper)
    });

    const [updateDeviceType] = useMutation(MUTATE_UPDATE_DEVICE_TYPE, {
        variables: {id: deviceType?.id},
    });


    //TODO: Because we now have to Querys:

    //const loading = useGraphqlLoadingComponent(deviceQueryResult);
    const loading = !deviceType
    Log.Debug("loading:", loading)
    if (loading) {
        //return loading;
        Log.Debug("DeviceType not loaded yet");
        return <Spinner className={""} containerClass={{}}/>
    }


    // Takes parsed data and unmarshal json fields
    const parsedDataMapper = (d) => {
        return {
            ...d,
            data: JSON.parse(d.dataRaw),
            //deviceProperties: JSON.parse(dataQueryResult.data.device.propertiesRaw || "{}") || {},
            dataRaw: undefined, // Hide dataRaw
        };
    };




    log.Debug("DeviceData result = ", deviceQueryResult)
    log.Debug("DeviceData result = ", dataQueryResult);


    let prefixCols = [{
        heading: t("device-type.data-table-config.heading.received", "Received"),
        csvFormat: "{{date createdAt}}",
        cell: {
            format: "{{date createdAt}}",
        }
    },
    ];


    let tableConfig = DEFAULT_DATA_TABLE_CONFIG;
    if (deviceType.dataTableConfigRaw) {
        tableConfig = JSON.parse(deviceType.dataTableConfigRaw);
    } else {
        prefixCols.push({
            "heading": t("device-type.data-table-config.heading.time", "time"),
            "csvFormat": "{{date time}}",
            "cell": {
                "format": "{{date time}}"
            }
        });
        // TODO: add optional datasource.name column even when dataTableConfigRaw exists
        prefixCols.push({
            "heading": t("device-type.data-table-config.heading.datasource", "Datasource"),
            "csvFormat": "{{datasource.name}}",
            "cell": {
                "format": "{{datasource.name}}"
            }
        });
    }

    return <GenericDataTable
        id={"parsed-data-table"}
        tableConfigDefault={tableConfig}
        gqlResult={dataQueryResult}
        prefixCols={prefixCols}
        handleTableConfigSave={(values) => {
            return updateDeviceType({
                variables: {
                    input: {
                        dataTableConfig: JSON.stringify(values),
                    }
                }
            }).catch((err) => {
                NotifyUser.Error(t("device-type.table-config.update-failed", "Failed to save Table Config"), err);
            });
        }}
        items={dataQueryResult?.data?.parsedData?.map(parsedDataMapper)}
        renderDetails={(item) => <ParsedDataDetailComponent traits={deviceType.deviceTraits} data={item}/>}
        hideDetails={false}
        page={page}
        sort={sort}
        csvExport={csvExport}
        filters={filters}
    />

}