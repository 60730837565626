import {useT} from "../common/i18n";
import {useAuthContext} from "../common/context/authContext";
import React, {useState} from "react";
import Highcharts from 'highcharts'
import HighchartsReact from "highcharts-react-official";
import gql from "graphql-tag";
import {useGraphqlLoadingComponent} from "../common/graphql";
import {useQuery} from "@apollo/client";
import {AccordionPanelControlled} from "../common/ui/accordion";



//sort: {field: "createdAt", direction: "DESC"}

const USAGE_STATISTICS_QUERY = gql`
    query($page: PaginationInputType, $sort: SortInputType, $filter: [FilterInputType!], $orgId: ID, $scope: String) {
        usageStatistics(page: $page, sort: $sort, filter: $filter, orgId: $orgId, scope: $scope) {
            id
            activeDevicesDay
            activeDevicesMonth
            devices
            statisticAt
            organisationName
            activeDevicesDayWithSubOrgs
            activeDevicesMonthWithSubOrgs
            devicesWithSubOrgs
        }
    }
`;

const QUERY_ORGANISATION = gql`
    query($orgId: ID!){
        organisation: getOrganisation(id: $orgId) {        
            subOrganisations {
                id
            }
        }}
`



const USAGE_STATISTICS_OGR_KEY = "lobaro.usageStatistics.org.show"
const USAGE_STATISTICS_OGR_WITH_SUB_KEY = "lobaro.usageStatistics.org_with_sub.show"
const USAGE_STATISTICS_ADMIN_KEY = "lobaro.usageStatistics.admin.show"

const UsageStatisticsOverview = () => {
    const t = useT();
    const auth = useAuthContext();
    const [forRerendering, setForRerendering] = useState(false);

    let organisationResult = useQuery(QUERY_ORGANISATION, {
        variables: {
            orgId: auth.organisationId(),
        },
    })



    const showOrgStats = localStorage.getItem(USAGE_STATISTICS_OGR_KEY) === "true" ? true : false;

    const showOrgWithSubOrgsStats = localStorage.getItem(USAGE_STATISTICS_OGR_WITH_SUB_KEY) == "true" ? true : false;
    const showAdminStats = localStorage.getItem(USAGE_STATISTICS_ADMIN_KEY) == "true" ? true : false;


    const usageStatisticsOrgResult = useQuery(USAGE_STATISTICS_QUERY, {
        variables: {
            orgId: auth.organisationId(),
            sort: {field: "statisticAt", direction: "ASC"},
            scope: "organisation"
        }
    })

    const usageStatisticsGlobalResult = useQuery(USAGE_STATISTICS_QUERY, {
        variables: {
            orgId: auth.organisationId(),
            sort: {field: "statisticAt", direction: "ASC"},
            scope: "global"
        },
        skip: !auth.hasRole("admin")
    })

    let loading = useGraphqlLoadingComponent(usageStatisticsOrgResult);
    if (loading) {
        return loading;
    }

    loading = useGraphqlLoadingComponent(organisationResult);
    if (loading) {
        return loading;
    }

    if (auth.hasRole("admin")) {
        loading = useGraphqlLoadingComponent(usageStatisticsGlobalResult);
        if (loading) {
            return loading;
        }
    }

    const org = organisationResult?.data?.organisation;

    const hasSubOrgs = org.subOrganisations.length > 0;

    const displaySubOrStats = (auth.hasRole("admin", "org-admin") && hasSubOrgs);


    const monthlyData = []
    usageStatisticsOrgResult.data?.usageStatistics?.forEach((item) => {
        monthlyData.push({x: new Date(item.statisticAt), y: item.activeDevicesMonth})
    })

    const dailyData = []
    usageStatisticsOrgResult.data?.usageStatistics?.forEach((item) => {
        dailyData.push({x:  new Date(item.statisticAt), y: item.activeDevicesDay})
    })

    const devicesData = []
    usageStatisticsOrgResult.data?.usageStatistics?.forEach((item) => {
        devicesData.push({x:  new Date(item.statisticAt), y: item.devices})
    })



    const monthlyDataWithSubOrgs = []
    const dailyDataWithSubOrgs = []
    const devicesDataWithSubOrgs = []
    if (auth.hasRole("org-admin", "admin")) {
        usageStatisticsOrgResult.data?.usageStatistics?.forEach((item) => {
            monthlyDataWithSubOrgs.push({x: new Date(item.statisticAt), y: item.activeDevicesMonthWithSubOrgs})
        })

        usageStatisticsOrgResult.data?.usageStatistics?.forEach((item) => {
            dailyDataWithSubOrgs.push({x:  new Date(item.statisticAt), y: item.activeDevicesDayWithSubOrgs})
        })

        usageStatisticsOrgResult.data?.usageStatistics?.forEach((item) => {
            devicesDataWithSubOrgs.push({x:  new Date(item.statisticAt), y: item.devicesWithSubOrgs})
        })
    }


    const globalMonthlyData = []
    const globalDailyData = []
    const globalDevicesData = []
    //global statistics
    if (auth.hasRole("admin")) {
        usageStatisticsGlobalResult.data?.usageStatistics?.forEach((item) => {
            globalMonthlyData.push({x: new Date(item.statisticAt), y: item.activeDevicesMonth})
        })

        usageStatisticsGlobalResult.data?.usageStatistics?.forEach((item) => {
            globalDailyData.push({x:  new Date(item.statisticAt), y: item.activeDevicesDay})
        })

        usageStatisticsGlobalResult.data?.usageStatistics?.forEach((item) => {
            globalDevicesData.push({x:  new Date(item.statisticAt), y: item.devices})
        })
    }

    const optionsOrg = {
        credits: {enabled: false},
        title: {
            text: ""
        },
        xAxis: {
            type: 'datetime'
        },
        yAxis: {
            title: {
                text: 'Devices'
            }
        },
        series: [
            {name: t("common.devices", "Devices"), data: devicesData},
            {name: t("usage-statistics.active-day", "Active Devices (Day)"), data: dailyData},
            {name: t("usage-statistics.active-month", "Active Devices (Month)"), data: monthlyData}
        ]
    }
    const optionsAdmin = {
        credits: {enabled: false},
        title: {
            text: ""
        },
        xAxis: {
            type: 'datetime'
        },
        yAxis: {
            title: {
                text: 'Devices'
            }
        },
        series: [
            {name: t("common.devices", "Devices"), data: globalDevicesData},
            {name: t("usage-statistics.active-day", "Active Devices (Day)"), data: globalDailyData},
            {name: t("usage-statistics.active-month", "Active Devices (Month)"), data: globalMonthlyData}
        ]
    }
    const optionsOrgWithSubOrg = {
        credits: {enabled: false},
        title: {
            text: ""
        },
        xAxis: {
            type: 'datetime'
        },
        yAxis: {
            title: {
                text: 'Devices'
            }
        },
        series: [
            {name: t("common.devices", "Devices"), data: devicesDataWithSubOrgs},
            {name: t("usage-statistics.active-day", "Active Devices (Day)"), data: dailyDataWithSubOrgs},
            {name: t("usage-statistics.active-month", "Active Devices (Month)"), data: monthlyDataWithSubOrgs}
        ]
    }

    console.log("showOrgStats", showOrgStats)
    return <div className="slds-grid slds-grow-none">
        <div className="slds-size_1-of-3">
          <AccordionPanelControlled id={"accordionStatisticsOrg"} summary={t("usage-statistics.headline.org", 'Usage Statistics (Organisation)')} expanded={showOrgStats} onTogglePanel={() => {
              localStorage.setItem(USAGE_STATISTICS_OGR_KEY, showOrgStats ? "false" : "true");
              setForRerendering(!forRerendering);
          }} >
            <HighchartsReact
                highcharts={Highcharts}
                options={optionsOrg}
            />
            </AccordionPanelControlled>
        </div>
        {auth.hasRole("org-admin", "admin") && displaySubOrStats &&
        <div className="slds-size_1-of-3">
            <AccordionPanelControlled id={"accordionStatisticsOrgsSub"} summary={t("usage-statistics.headline.org-with-suborgs", 'Usage Statistics (Organisation With SubOrgs)')} expanded={showOrgWithSubOrgsStats} onTogglePanel={() => {
                localStorage.setItem(USAGE_STATISTICS_OGR_WITH_SUB_KEY, showOrgWithSubOrgsStats ? "false" : "true");
                setForRerendering(!forRerendering);
            }} >
            <HighchartsReact
                highcharts={Highcharts}
                options={optionsOrgWithSubOrg}
            />
            </AccordionPanelControlled>
        </div>}
        {auth.hasRole("admin") &&
        <div className="slds-size_1-of-3">
            <AccordionPanelControlled id={"accordionStatisticsAdmin"} summary={t("usage-statistics.headline.global", "Usage Statistics (Platform)")} expanded={showAdminStats} onTogglePanel={() => {
                localStorage.setItem(USAGE_STATISTICS_ADMIN_KEY, showAdminStats ? "false" : "true");
                setForRerendering(!forRerendering);
            }}  >
            <HighchartsReact
                highcharts={Highcharts}
                options={optionsAdmin}
            />
            </AccordionPanelControlled>
        </div>
        }
    </div>
}

export default UsageStatisticsOverview;