import {usePagination} from "../../common/hooks/usePagination";
import {useQuery} from "@apollo/client";
import gql from "graphql-tag";
import GenericDataTable from "../../common/ui/genericDataTable/genericDataTable";
import DataTableColumn from "../../common/ui/data-table/column";
import {Link} from "react-router-dom";
import moment from "moment";
import React from "react";
import {UserRolePills} from "../user/UserRolesField";
import {useT} from "../../common/i18n";

export const OrganisationUserList = (props) => {
    const page = usePagination();
    const t = useT();
    const basePath = props.basePath;
    const organisationId = props.organisationId

    const usersListResult = useQuery(gql`
        query($page: PaginationInputType, $orgId: ID ) {
            getUserList(page: $page, sort: {field: "createdAt", direction: "DESC"}, orgId: $orgId) {
                id
                login
                organisation {
                    id
                }
                baseOrganisation {
                    id
                }
                createdAt
                roles
                email
                lastLogin
            }
        }
    `, {
        variables: {
            orgId: organisationId
        }
    });

    let items = usersListResult.data && usersListResult.data.getUserList

    return  <GenericDataTable id={"user-table"} items={items} tableConfigDefault={{}} gqlResult={usersListResult}
                              page={page}>
        <DataTableColumn label="ID" property={"id"}
                         render={(props) => <Link to={basePath+`${props.item.id}`}>{props.item.id}</Link>}
        />
        <DataTableColumn label={t("user.login","Login")} property="login" title={"login"}
                         render={(props) => <Link to={basePath+`${props.item.id}`}>{props.item.login}</Link>}/>

        <DataTableColumn label="E-Mail" property="email" title={"email"}/>
        <DataTableColumn label={t("user.roles","Roles")} property="roles" title={"roles"} render={ (props) =>  {return <UserRolePills roles={props.item.roles}/>}} />
        <DataTableColumn label={t("user.created","Created")} property={"createdAt"} title={"createdAt"} render={
            (props) => {
                return <div
                    className="slds-cell-wrap">{moment(props.item.createdAt).fromNow()} ({moment(props.item.createdAt).format('DD.MM.YYYY HH:mm:ss')})</div>}
        }/>
        <DataTableColumn label={t("user.last-login","Last Login")} property={"lastLogin"} title={"lastLogin"}  render={(props) => {
            return <div
                className="slds-cell-wrap">{lastLoginCell(props.item?.lastLogin)}</div>;
        }}/>
    </GenericDataTable>

};

function lastLoginCell(lastLogin){
    if (lastLogin) {
        return `${moment(lastLogin).fromNow()} ${moment(lastLogin).format('DD.MM.YYYY HH:mm:ss')}`
    } else {
        return "never"
    }

}