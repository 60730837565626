import {useEffect, useState} from "react";
import {useDebounce} from "./useDebounce";

type SortInfo = {
    property?: string
    direction?: "ASC" | "DESC"
}

class Sort {
    sortInfo: SortInfo
    setSortInfo: (SortInfo) => void
    resetFunction: () => void

    constructor(sortInfo: SortInfo, setSortInfo: (SortInfo) => void, reset: () => void) {
        this.sortInfo = sortInfo;
        this.setSortInfo = setSortInfo;
        this.resetFunction = reset
    }

    getGraphqlSortInput() {
        if (!this.sortInfo.property) {
            return null;
        }
        return {
            field: this.sortInfo.property || "",
            direction: this.sortInfo.direction || "ASC",
        }
    }

    reset() {
        this.resetFunction()
    }

    isSorted() {
        return !!this.sortInfo.property;
    }
}

export function useSort(initialSort = {}, refetchQuery = null) {
    if (initialSort == null) {
        initialSort = {}
    }
    const [sortInfo, setSortInfo] = useState<SortInfo>(initialSort);

    function reset() {
        setSortInfo(initialSort)
    }

    const sort = new Sort(sortInfo, setSortInfo, reset);
    const refetch = useDebounce(refetchQuery, 250)
    useEffect(() => {
            if (refetchQuery) {
                refetch({
                    sort: sort.getGraphqlSortInput(),
                })
            }
        },
        [sortInfo]
    )

    return new Sort(sortInfo, setSortInfo, reset);
}