import {useState} from "react";

// Helper to search in data tables
export function useSearch(initialSearchKeyword) {
    const [search, setSearch] = useState(initialSearchKeyword);

    return {
        setKeyword: setSearch,
        keyword: search,
        getGraphqlSearchInput: () => search
    }
}