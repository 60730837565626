import {useQuery} from "@apollo/client";
import gql from "graphql-tag";
import {useContext} from "react";
import {AppContext} from "../../../app/appPage";
import {useGraphqlLoadingComponent} from "../../graphql";
import {Log} from "../../log";

const QUERY_APP_TABLE_CONFIG = gql`
    query getAppTableConfig($appId: ID!, $name: String!) {
        appTableConfig: getAppTableConfig(appId: $appId, name: $name) {
            id
            appId
            name
            config
        }
    }
`;

// Load a table config from graphQL or default value
export function useAppTableConfig(tableConfigName, tableConfigDefault = {cols: []}) {
    const app = useContext(AppContext);

    // No app table config when no app
    Log.Debug("app.id", app.id, tableConfigDefault);
    if (!app.id) {
        return {
            result: null,
            loading: null,
            tableConfig: tableConfigDefault,
        }
    }
    let appTableConfigResult = useQuery(QUERY_APP_TABLE_CONFIG, {
        skip: !tableConfigName,
        fetchPolicy: "network-only",
        variables: {
            appId: app.id,
            name: tableConfigName,
        }
    });

    const loading = useGraphqlLoadingComponent(appTableConfigResult);
    let tableConfig;
    const {data} = appTableConfigResult;
    if (data && data.appTableConfig && data.appTableConfig.config) {
        tableConfig = JSON.parse(data.appTableConfig.config);
    }

    if (!loading && !tableConfig) {
        Log.Debug("!!! Not Loading and no Tableconfig, using default", appTableConfigResult)
        tableConfig = tableConfigDefault;
    }


    return {
        result: appTableConfigResult,
        loading: loading,
        tableConfig: tableConfig,
    }
}