import React from "react";
import {Link, Outlet} from "react-router-dom";
import Page from "../common/ui/page";
import {Tab, TabNavigation} from "../common/ui/tabNavigation";
import {FrontendConfigContext} from "../common/context/frontendConfigContext";


const WmbusApiModule = () => {
    const frontendConfig =  React.useContext(FrontendConfigContext)
    const tabs = [<Tab key={"parser"} to={"parser"}>Parser</Tab>]
    if (frontendConfig?.wmbusParserApiTabEnabled) {
        tabs.push(<Tab key={"api"} to={"api"}>API</Tab>)
    }

    return <TabNavigation tabs={tabs}>
        <Page title={"wMbus API"}
              trail={[<Link to={"/"} key={1}>wMbus API</Link>]}
        >
            <Outlet/>
        </Page>
    </TabNavigation>;
};

export default WmbusApiModule;